<template>
  <div>
    <div class="footer-1 p-md-5" id="retail-footer">
      <div class="row">
        <div class="col-md-3">
          <p class="font-weight-bold mb-0">Penta Real Estate</p>
          <p class="m-0">Digital Park II</p>
          <p class="m-0">Einsteinova 25</p>
          <p class="m-0">Bratislava</p>
          <p class="m-0">Slovenská republika</p>
          <p class="m-0"><a target="_blank" href="https://www.google.com/maps/place/Digital+Park+II,+Einsteinova+23,+851+01+Petr%C5%BEalka/@48.1301763,17.1050219,17z/data=!3m1!4b1!4m5!3m4!1s0x476c897221a35f19:0xfceab18ad724e22!8m2!3d48.1301763!4d17.1072106">{{ $t('Zobraziť na mape') }}</a></p>
        </div>
        <div class="col-md-3 mb-md-5 mb-md-0"></div>
        <div class="col-md-3 mb-5 mb-md-0"></div>
        <div class="col-md-3">
          <h3>{{ $t('Kontakt') }}</h3>
          <p class="m-0">Michal Hranai</p>
          <p class="m-0"><a href="mailto:hranai@pentarealestate.com">hranai@pentarealestate.com</a></p>
          <p><a href="tel:+421910928229">+421 910 928 229</a></p>

          <p class="m-0">{{ $t('V prípade záujmu o voľné pozemky nás kontaktujte.') }}</p>
        </div>
      </div>
      <div class="row mt-5 mt-md-0 bottom-links">
        <div class="col-12">
          <p class="font-weight-bold">{{ $t('Všetky vizualizácie zobrazené na stránke sú ilustračné.') }}</p>
          <a class="mr-3 d-block d-md-inline" v-b-modal.agreements>{{ $t('Spracovanie osobných údajov') }}</a>
          <a class="mr-3" v-b-modal.cookies>{{ $t('Cookies') }}</a>
          <a>Design by Milk Design Directors</a>
        </div>
      </div>
    </div>
    <div class="footer-2 px-3 px-md-5 py-4">
      <div class="row align-items-center">
        <div class="col-md-4 mb-3 mb-md-0 mobile-last text-center text-md-left">
          <p class="m-0">© All rights reserved by PENTA REAL ESTATE</p>
        </div>
        <div class="col-md-4 mb-4 mb-md-0 text-center">
          <img src="../assets/images/penta-re-logo.svg" alt="Penta real estate">
        </div>
        <div class="col-md-4 mb-4 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-end text-center text-md-right social-icons">
          <a href="https://www.pentarealestate.com/sk/" class="social penta ml-0" target="_blank"></a>
          <a href="https://www.facebook.com/RealEstatePenta/" class="social fb" target="_blank"></a>
          <a href="https://www.instagram.com/penta_real_estate/" class="social ig" target="_blank"></a>
          <a href="https://www.linkedin.com/company/28652117/" class="social in" target="_blank"></a>
        </div>
      </div>
    </div>
    <b-modal id="cookies" scrollable hide-footer title="Zásady používania súborov cookies na webovej stránke www.borybyvanie.sk" size="xl">
      <CookiesModal></CookiesModal>
    </b-modal>
    <b-modal id="agreements" scrollable hide-footer title="PRAVIDLÁ SPRACÚVANIA OSOBNÝCH ÚDAJOV" size="xl">
      <AgreementsModal></AgreementsModal>
    </b-modal>
  </div>
</template>

<script>
import CookiesModal from "@/components/CookiesModal";
import AgreementsModal from "@/components/AgreementsModal";
export default {
  name: "Footer",
  components: {AgreementsModal, CookiesModal}
}
</script>

<style lang="sass" scoped>
$md: 768px
.footer-1
  background: #21252B
  @media (max-width: $md)
    padding: 30px
  h3
    font-size: 18px
    line-height: 22px
    margin-bottom: 30px
    display: flex
    align-items: center
    img
      margin-right: 10px
  p
    font-size: 16px
    color: #D5DBDF
    font-weight: 500
    &.font-weight-bold
      color: #fff
  a
    color: var(--primary)
  .bottom-links
    font-size: 14px
    a
      color: var(--grey)
      font-weight: 500
.footer-2
  color: var(--text-dark)
  p
    font-size: 14px
    font-weight: 500
    color: #000
  .social-icons
    .social
      width: 26px
      height: 26px
      display: inline-block
      background: url(../assets/images/social-icons.svg) no-repeat
      margin-left: 8px
      &.penta
        background: url(../assets/images/penta-icon.svg) no-repeat
      &.ig
        background-position: center
      &.in
        background-position: right
  .mobile-last
    @media (max-width: $md)
      order: 3
</style>