function getCookies(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export default {
	namespaced: true,

	state: { $locale: getCookies('locale') },
    mutations: { updateLocale: function(state, newLang) { state.$locale = newLang; } }

}

