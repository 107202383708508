<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div class="position-relative">
          <h1>{{ $t('Žalúzie a kuchyňa v cene bytu') }}</h1>
          <p class="mb-4" v-html="$t('Vyberte si z posledných dostupných bytov v etape Bory Promenáda <br>a&nbsp;získajte vybavenie v hodnote až do 12 000 € v cene bytu.*')"></p>
          <p class="disclaimer" v-html="$t('akcia_zaluzie_text')"></p>
          <div class="badge-offer">
            <img src="../assets/images/kuchyne/badge.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <section class="section-3 free-offer p-3">
      <div class="">
        <div class="row px-3">
          <div class="col-md-5 p-0 px-md-5 py-5 pb-custom">
            <h2 class="mb-4" v-html="$t('Exteriérové žalúzie na&nbsp;všetkých oknách')"></h2>
            <p class="mb-5" v-html="$t('Netrápte sa výberom dodávateľa ani zdĺhavou montážou. V&nbsp;posledných voľných bytoch sú žalúzie namontované už v cene bytu.')"></p>
            <b-button to="/byvanie/bory3" class="d-block d-md-inline-block px-5" squared variant="primary">{{ $t('Pozrieť ponuku bytov') }}</b-button>
          </div>
          <div class="col-md-7 bg-img"></div>
        </div>
      </div>
    </section>

    <section class="section-3 dark-bg p-3">
      <div class="">
        <div class="row px-3">
          <div class="col-md-7 bg-img-2"></div>
          <div class="col-md-5 p-0 px-md-5 py-5 pb-custom-2">
            <h2 class="mb-4">{{ $t('Kuchyňa v cene bytu') }}</h2>
            <p class="mb-5" v-html="$t('Ušetrite čas hľadaním riešenia Vašej novej kuchynskej linky. Pre posledné byty v etape Promenáda sme navrhli štýlové a najmä praktické kuchynské linky so spotrebičmi v hodnote až&nbsp;do&nbsp;10&nbsp;000&nbsp;€.')"></p>
            <b-button to="/byvanie/bory3" class="d-block d-md-inline-block px-5" squared variant="primary">{{ $t('Pozrieť ponuku bytov') }}</b-button>
          </div>
        </div>
      </div>
    </section>

    <div class="catalog">
      <div class="container">
        <div id="catalog-slider">
          <h2>{{ $t('Katalóg kuchynských prevedení') }}</h2>
          <slick
              ref="slickStandard"
              :options="slickOptions"
              class="slider-for mb-4"
          >
            <div class="slide">
              <a href="../byty/bory3/B1.108" target="_blank">
                <img src="../assets/images/kuchyne/B1_108.png" alt="">
                <div class="type">{{ $t('Byt') }} B1.108</div>
              </a>
            </div>
            <div class="slide">
              <a href="../byty/bory3/B3.208" target="_blank">
                <img src="../assets/images/kuchyne/B3_208.png" alt="">
                <div class="type">{{ $t('Byt') }} B3.208</div>
              </a>
            </div>
            <div class="slide">
              <a href="../byty/bory3/D1.101" target="_blank">
                <img src="../assets/images/kuchyne/D1_101.png" alt="">
                <div class="type">{{ $t('Byt') }} D1.101</div>
              </a>
            </div>
          </slick>
          <p class="text-center">Farebné prevedenie kuchynskej linky je možné upraviť po konzultácii s&nbsp;predajcom.</p>
          <p class="text-center">Prečítajte si <a href="../../docs/Podmienky_akcie_kuchynska_linka.pdf" target="_blank">Podmienky akcie</a> kuchynská linka v cene bytu  pre bližšie informácie.</p>
        </div>
      </div>
    </div>

    <section class="section-3 free-offer p-3">
      <div class="">
        <div class="row px-3">
          <div class="col-md-5 p-0 px-md-5 py-5 pb-custom">
            <h2 class="mb-4">{{ $t('V Bory Promenáda môžete bývať ihneď') }}</h2>
            <p class="mb-5" v-html="$t('Vyberte si z posledných dostupných bytov v&nbsp;etape a získajte vybavenie v&nbsp;cene bytu.')"></p>
            <b-button to="/byvanie/bory3" class="d-block d-md-inline-block px-5" squared variant="primary">{{ $t('Pozrieť ponuku bytov') }}</b-button>
          </div>
          <div class="col-md-7 bg-img-3"></div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ScrollTo from "@/plugins/scrollTo.util";
import ContactForm from "@/components/ContactForm";
import Slick from "vue-slick";

export default {
  name: "Bory3",
  components: {ContactForm, Slick},
  data() {
    return {
      slickOptions: {
        infinite: false,
        adaptiveHeight: true,
        dots: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        // Any other options that can be got from plugin documentation
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      },
    }
  },
  mounted () {
    ScrollTo.hash(this,  () => window.scrollTo(0, 0))
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.about-bottom-2
  .bg
    background: url(../assets/images/bory3/bottom-image.jpg) center/cover no-repeat
.about-bottom
  .right-img
    background: url(../assets/images/cam_bedroom_01.jpg) center/cover no-repeat


.catalog
  background: var(--darkBlue)
  padding: 80px 0

  #catalog-slider
    overflow: hidden

  h2
    font-size: 48px
    padding-left: 8px
    margin-bottom: 80px

  .slide
    position: relative

    .type
      position: absolute
      left: 0
      bottom: 0
      background: var(--blue)
      padding: 8px 30px
      font-size: 20px
      color: var(--dark)

.section-3
  background: var(--grey)
  color: #fff
  text-align: left

  .pb-custom
    padding-bottom: 100px !important

    @media (max-width: $md)
      padding-bottom: 30px !important

    &-2
      padding-bottom: 130px !important
      @media (max-width: $md)
        padding-bottom: 30px !important

  &.dark-bg
    background: var(--text-dark)

  &.free-offer
    .bg-img
      background: url(../assets/images/BH3-JUN-2024-lowres-1015.jpg) center/cover no-repeat
  .bg-img
    background: url("../assets/images/Bory_Promenada-3.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  .bg-img-2
    background: url(../assets/images/kuchyne/cam_livingroom_update.jpg) center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  .bg-img-3
    background: url("../assets/images/kuchyne/Bory_BH3-41.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 360px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory3/bory3.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/Bory_Promenada-1.jpg") bottom/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory3/bory3-ihriska.jpg") center/cover no-repeat
    background-position: -350px center
    @media (max-width: $md)
      background-position: center
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/kuchyne/home.jpg") bottom/cover no-repeat
  p
    margin-bottom: 30px
    max-width: 770px

    &.disclaimer
      font-size: 14px
      font-weight: bold
      font-style: italic

      a
        color: #fff
        text-decoration: underline

  .badge-offer
    position: absolute
    top: -220px
    right: -220px
    width: 240px

    @media (max-width: $md)
      top: -70%
      left: 50%
      transform: translateX(-50%)
      max-width: 160px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
