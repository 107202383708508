<template>
  <div v-if="flats.length" class="d-flex flex-wrap filter pt-md-5" :class="{ background: !sideMenu }">
    <div :class="{ 'col-md-4': !sideMenu }">
      <div class="buttons phase mb-4" v-if="showStageAndDate">
        <h3 class="mb-3">{{ $t('Etapa') }}</h3>
        <b-button 
          v-for="(btn, idx) in stages" :key="idx"
          squared 
          variant="outline-primary" 
          :class="{ active: isActive('stages', btn.value) }"
          @click="flatsFilter('stages', btn.value)" 
        >{{btn.text}}</b-button>
      </div>

      <div v-if="!sideMenu && showStageAndDate && selectedStages.length" class="buttons date mb-4">
        <h3 class="mb-3">{{ $t('Termín dokončenia') }}</h3>
        <div class="d-flex flex-row-reverse justify-content-end">
          <b-button
              v-for="(btn, idx) in dates" :key="idx"
              squared
              variant="outline-primary"
              :class="{ active: isActive('dates', btn.text) }"
              @click="flatsFilter('dates', btn.text)"
          >{{btn.text.replaceAll('.', '. ')}}</b-button>
        </div>
      </div>

      <div v-if="!sideMenu && showDate" class="buttons date mb-4">
        <h3 class="mb-3">{{ $t('Termín dokončenia') }}</h3>
        <!--    Docasne riesenie, treba aby chodil tento text z API ako termin dokoncenia    -->
        <b-button
            squared
            variant="outline-primary"
            :class="{ active: isActive('dates', '3.Q.2020') }"
            @click="flatsFilter('dates', '3.Q.2020')"
        >3.Q.2020</b-button>
        <b-button
            squared
            variant="outline-primary"
            :class="{ active: isActive('dates', '2.Q.2023') }"
            @click="flatsFilter('dates', '2.Q.2023')"
        >2.Q.2023</b-button>
        <!--   END Docasne riesenie     -->
      </div>
      
      <div v-if="!sideMenu && selectedStages.length" class="buttons building">
        <h3 class="mb-3">{{ $t('Dom') }}</h3>
        <b-button 
          v-for="(building, idx) in buildings" :key="idx"
          squared 
          variant="outline-primary" 
          :class="{ active: isActive('buildings', building.text) }"
          @click="flatsFilter('buildings', building.text)" 
        >{{building.text}}</b-button>
      </div>
    </div>

    <div :class="{ 'col-md-4': !sideMenu }">
      <div class="buttons rooms mb-4">
        <h3 class="mb-3">{{ $t('Počet izieb') }}</h3>
        <b-button 
          v-for="(room, idx) in rooms" :key="idx"
          squared 
          variant="outline-primary" 
          :class="{ active: isActive('rooms', room.text) }"
          @click="flatsFilter('rooms', room.text)" 
        >{{room.text}}</b-button>
      </div>

      <div class="buttons floor mb-4">
        <h3 class="mb-3">{{ $t('Podlažie') }}</h3>
        <b-button 
          v-for="(floor, idx) in floors" :key="idx"
          squared 
          variant="outline-primary" 
          :class="{ active: isActive('floors', floor.text) }"
          @click="flatsFilter('floors', floor.text)"
        >{{floor.text}}</b-button>
      </div>

      <div v-if="!sideMenu" class="buttons availability" :class="{visible: showExtendend}">
        <h3 class="mb-3">{{ $t('Dostupnosť') }}</h3>
        <b-button
          v-for="(avl, i) in availability" :key="i"
          squared 
          variant="outline-primary"
          :class="{ active: value.availability === avl.id }"
          @click="value.availability = avl.id"
        >{{ avl.label }}</b-button>
      </div>
    </div>

    <div :class="{ 'col-md-4': !sideMenu, 'w-100': sideMenu, 'visible': showExtendend }" class="p-5 p-md-0 availability">
      <div class="mb-5">
        <h3 class="mb-3">{{ $t('Celková plocha (m2)') }}</h3>
        <vue-slider 
          v-model="totalArea" 
          :min="totalAreaMin" :max="totalAreaMax" 
          :enable-cross="false" 
          :tooltip="'always'" 
          :tooltip-placement="['bottom', 'bottom']"
        ></vue-slider>
      </div>

      <div class="mt-5 pb-4">
        <h3 class="mb-3">{{ $t('Cena s DPH') }}</h3>
        <vue-slider 
          v-model="priceVat" 
          :min="priceVatMin" :max="priceVatMax" 
          :interval="100" 
          :enable-cross="false"
          :tooltip="'always'" 
          :tooltip-placement="['bottom', 'bottom']"
          :tooltip-formatter="formatter1"
        ></vue-slider>
      </div>
    </div>

    <div class="mobile-filter px-3">
      <div @click="showExtendedFilter" class="filter-button">
        <img src="../assets/icons/filter-icon.svg" alt="">
        <span v-if="!showExtendend">{{ $t('Rozšírený') }}&nbsp;</span> <span v-if="showExtendend">{{ $t('Skryť') }}&nbsp;</span> {{ $t('filter') }}
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import collect from 'collect.js'

  import VueSlider from 'vue-slider-component'

  export default Vue.extend({

    components: {
      VueSlider
    },

    props: {
      value: {
        type: Object,
        required: true
      },
      sideMenu: {
        type: Boolean,
        required: false,
        default: false
      },
      getStage: {
        required: false,
        default: () => []
      },
      showStageAndDate: {
        type: Boolean,
        required: false,
        default: true
      },
      showDate: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    
    data() {
      return {
        stages: [
          {text: 'Promenáda', value: 'bory3', state: false},
          {text: 'Nový Dvor', value: 'bory2b', state: false},
          {text: 'Na Hrádzi', value: 'bory4', state: false},
          // {text: 'Nádvorie', value: 'bory5', state: false}
        ],
        dates: [],
        buildings: [],
        rooms: [],
        floors: [],
        availability: [
          { label: this.$t('Voľné'), id: 0 },
          { label: this.$t('Predrezervované'), id: 1 },
          { label: this.$t('Všetky'), id: 'all' }
        ],
        
        totalAreaMin: 0,
        totalAreaMax: 1000,
        totalArea: [0, 1000],

        priceVat: [0, 500000],
        priceVatMin: 0,
        priceVatMax: 500000,
        selectedStages: [],
        showExtendend: false,
        formatter1: v => `${('' + v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
      }
    },

    created() {
      if (!this.showStageAndDate) {
        this.selectedStages = [{}]
      }
    },

    computed: {
      ...mapGetters('flats', ['flats'])
    },

    methods: {
      showExtendedFilter() {
        this.showExtendend = !this.showExtendend
      },
      flatsFilter(filterProperty, value) {
        // console.log(this.value.stages)
        const formatter = function (name) {
          this.text = name
          this.state = false
        }
        // console.log(filterProperty)
        // if (filterProperty == 'stages') {
        //   const collectItems = collect(this.flats).where('building.building_stage', '==', value)
        //   this.buildings = collectItems.pluck('building.building_name').unique().sort().mapInto(formatter)
        // }
        // const selectedStages = this.value.stages
        // console.log(selectedStages)

        // this.buildings = this.flats.filter(function(item) {
        //   selectedStages.forEach(function(key) {
        //     console.log(item)
        //     console.log(key)
        //     if (item.building.building_stage != key)
        //       return false
        //   })
        //   return true
        //   // for (const (asd, value) in selectedStages) {
        //   //   console.log(asd)
        //   //   if (item.building.building_stage == asd)
        //   //     return true
        //   // }
        //   // return false
        // })
        
        if (this.showStageAndDate) {
          if (this.selectedStages.includes(value)) {
            const index = this.selectedStages.indexOf(value);
            if (index > -1) {
              this.selectedStages.splice(index, 1);
            }
          } else {
            this.selectedStages.push(value)
          }

          const collectItems = collect(this.flats).where('flat_status', '<', '2')

          this.buildings = collectItems.whereIn('building.building_stage', this.selectedStages).pluck('building.building_name').unique().sort().mapInto(formatter)
          this.dates = collectItems.whereIn('building.building_stage', this.selectedStages).pluck('building.stage_completion_date').unique().sort().filter().mapInto(formatter)
        }
        
        this.$root.$emit('filterChanged', null)
        const obj = { ...this.value }
        obj[filterProperty] = obj[filterProperty] || []

        if (!this.isActive(filterProperty, value))
          obj[filterProperty].push(value)
        else 
          obj[filterProperty] = obj[filterProperty].filter(val => val !== value)

        this.$emit('input', obj)
      },

      isActive(prop, val) {
        return this.value[prop] && this.value[prop].includes(val)
      },

      _initFilters() {
        const formatter = function (name) {
          this.text = name
          this.state = false
        }
        const collectItems = collect(this.flats).where('flat_status', '<', '2')

        this.totalAreaMin = Math.floor(collectItems.min('flat_area'))
        this.totalAreaMax = Math.ceil(collectItems.max('flat_area'))
        this.totalArea = this.value.totalArea || [this.totalAreaMin, this.totalAreaMax]
        this.$emit('input', {
          ...this.value,
          totalArea: [...this.totalArea]
        })

        this.priceVatMin = collectItems.min('flat_price')
        this.priceVatMax = collectItems.max('flat_price')
        this.priceVat = this.value.priceVat || [this.priceVatMin, this.priceVatMax]
        this.$emit('input', {
          ...this.value,
          priceVat: [...this.priceVat]
        })

        this.rooms = collectItems.pluck('flat_number_of_rooms').unique().sort().filter().mapInto(formatter)
        this.floors = collectItems.pluck('building.floor_number').unique().sort().mapInto(formatter)
        if (!this.showStageAndDate) {
          this.dates = collectItems.pluck('building.stage_completion_date').unique().sort().filter().mapInto(formatter)
          this.buildings = collectItems.pluck('building.building_name').unique().sort().mapInto(formatter)
        }
        if (this.getStage[0]) {
          // toto vymazat ak budu opat fazy rozdelene a nebudeme zobrazovat dve stage v jednom, potom nechat len posledny riadok
          if (this.$route.query.stage === 'complete' && this.getStage[0] === 'bory2b') {
            const arr1 = collectItems.where('building.building_stage', 'bory2').pluck('building.building_name').unique().sort().mapInto(formatter)
            const arr2 = collectItems.where('building.building_stage', 'bory2b').pluck('building.building_name').unique().sort().mapInto(formatter)
            this.buildings = arr1.items.concat(arr2.items)
          } else
            this.buildings = collectItems.where('building.building_stage', this.getStage[0]).pluck('building.building_name').unique().sort().mapInto(formatter)
        }
      }
    },

    watch: {
      flats() {
        this._initFilters()
      },

      value() {
        if (this.value.totalArea)
          this.totalArea = this.value.totalArea
        if (this.value.priceVat)
          this.priceVat = this.value.priceVat
      },

      priceVat(val) {
        this.$emit('input', {
          ...this.value,
          priceVat: val
        })
      },

      totalArea(val) {
        this.$emit('input', {
          ...this.value,
          totalArea: val
        })
      }
    }
    
  })
</script>

<style lang="sass" scoped>
$md: 768px

.mobile-filter
  display: none
  @media (max-width: $md)
    display: block
  .filter-button
    color: var(--blue)
    display: flex
    align-items: center

    img
      margin-right: 10px

.filter
  &.background
    padding: 50px 100px
    background: var(--textBright)
    @media (max-width: $md)
      padding: 20px 5px

  .availability
    @media (max-width: $md)
      display: none

      &.visible
        display: block
      
  h3
    font-weight: 500
    font-size: 24px
    line-height: 28px
    letter-spacing: -0.5px
  .btn-outline-primary
    color: #fff
    margin: 3px
    padding: 7px 15px
    outline: none
    box-shadow: none!important
    &:not(.disabled).active, &:not(.disabled):active
      background: var(--blue)
      border-color: var(--blue)
</style>