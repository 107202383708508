<template>
  <section class="projects p-md-5">
    <div class="d-flex justify-content-between projects-header">
      <h2>{{ $t('Aktuálne projekty') }}</h2>
      <div class="filter">
        <button class="btn m-2" @click="filter('show all')" :class="['show all'===filterOption? 'active' : '']">{{ $t('Všetky projekty') }}</button>
        <button class="btn m-2" @click="filter('nova-etapa')" :class="['nova-etapa'===filterOption? 'active' : '']">{{ $t('Nová etapa') }}</button>
        <button class="btn m-2" @click="filter('v-predaji')" :class="['v-predaji'===filterOption? 'active' : '']">{{ $t('V predaji') }}</button>
        <button class="btn m-2" @click="filter('dokoncene')" :class="['dokoncene'===filterOption? 'active' : '']">{{ $t('Dokončené') }}</button>
      </div>
    </div>
    <div class="projects-wrapper">
      <isotope ref="cpt" id="root_isotope1" :item-selector="'element-item'" :list="projects" :options='option' @filter="filterOption=arguments[0]">
        <div class="project" v-for="(item, idx) in projects" :key="idx" :class="[item.category, item.customClass]">
          <div class="">
            <div class="img" :class="item.imageClass">
              <b-link class="link" :to="item.link"></b-link>
              <div class="status" :class="item.statusClass" v-html="$t(item.status)"></div>
              <div class="show-project">{{ $t('Zobraziť projekt') }}</div>
            </div>
            <p>{{ $t(item.title) }}</p>
          </div>
        </div>
        <!-- <div class="col-md-4 nova-etapa v-predaji">
          <div class="img large na-hradzi">
            <b-link class="link" to="/byvanie/na-hradzi"></b-link>
            <div class="status registration">Nová etapa v predaji</div>
          </div>
          <p>Bory Na Hrádzi</p>
        </div>
        <div class="col-md-4 v-predaji">
          <div class="img large pri-jazierku">
            <b-link class="link" to="/byvanie/novy-dvor#mapbox"></b-link>
            <div class="status registration">Nové byty v predaji</div>
          </div>
          <p>Bory Nový Dvor</p>
        </div>
        <div class="col-md-4 v-predaji">
          <div class="img small bory3-img">
            <b-link class="link" to="/byvanie/bory3"></b-link>
            <div class="status sale">V predaji</div>
          </div>
          <p><b-link to="/byvanie/bory3">Bory Promenáda</b-link></p>
          <div class="img small bory1-img">
            <b-link class="link" to="/byvanie/bory1"></b-link>
            <div class="status done">Dokončené</div>
          </div>
          <p><b-link to="/byvanie/bory1">Bory Prvé Domy</b-link></p>
        </div> -->
      </isotope>
    </div>
  </section>
</template>

<script>
import isotope from 'vueisotope'
export default {
  name: "Projects",
  components: {
    isotope
  },
  data() {
    return {
      projects: [
        {
          status: 'Nová etapa v príprave',
          link: '/byvanie/nadvorie',
          title: 'Bory Nádvorie',
          category: 'nova-etapa',
          imageClass: 'large nadvorie',
          customClass: '',
          statusClass: 'registration'
        },
        {
          status: 'Nové byty v predaji',
          link: '/byvanie/na-hradzi',
          title: 'Bory Na Hrádzi',
          category: 'v-predaji',
          imageClass: 'small na-hradzi',
          customClass: 'small',
          statusClass: 'registration'
        },
        {
          status: 'Bývajte ihneď',
          link: '/byvanie/novy-dvor',
          title: 'Bory Nový Dvor',
          category: 'v-predaji',
          imageClass: 'small pri-jazierku',
          customClass: 'small',
          statusClass: 'registration'
        },
        {
          status: 'Posledné byty <br>k nasťahovaniu ihneď',
          link: '/byvanie/bory3',
          title: 'Bory Promenáda',
          category: 'v-predaji',
          imageClass: 'small bory3-img',
          customClass: 'small mt-0',
          statusClass: 'registration'
        },
        {
          status: 'Dokončené',
          link: '/byvanie/bory1',
          title: 'Bory Prvé Domy',
          category: 'dokoncene',
          imageClass: 'small bory1-img',
          customClass: 'small',
          statusClass: 'done'
        }
      ],
      filterOption: "show all",
      option: {
        itemSelector: ".element-item",
        getFilterData: {
          "show all": function() {
            return true;
          },
          'nova-etapa': function(el) {
            return el.category === "nova-etapa";
          },
          'v-predaji': function(el) {
            return el.category === "nova-etapa" || el.category === "v-predaji"
          },
          'dokoncene': function(el) {
            return el.category === "dokoncene";
          }
        }
      }
    };
  },
  methods: {
    filter: function(key) {
      this.$refs.cpt.filter(key)
    }
  },
}
</script>

<style lang="sass" scoped>
$md: 768px

.projects-wrapper
  margin-left: -16px
  margin-right: -16px

  @media (max-width: $md)
    padding: 15px
    max-width: 100%
    margin: 0

.projects-header
  @media (max-width: $md)
    flex-direction: column

.filter
  @media (max-width: $md)
    display: flex
    flex-wrap: nowrap
    overflow-x: auto
    -webkit-overflow-scrolling: touch
    -ms-overflow-style: -ms-autohiding-scrollbar
    margin-bottom: 15px
    margin-right: -15px

    &::-webkit-scrollbar
      display: none
  .btn
    @media (max-width: $md)
      width: auto
      flex: 0 0 auto

.element-item
  width: calc(33.333% - 30px)
  float: left
  margin: 15px
  position: relative

  @media (max-width: $md)
    width: 100%
    margin: 0

  &.small
    margin-bottom: 0
    margin-right: 0

    &:last-child
      margin-top: 0

    &:has(~ [style*='display: none'])
      .img
        height: 67vh

.link
  position: absolute
  width: 100%
  height: 100%
  left: 0
  z-index: 99
.projects
  background: #3F4752
  @media (max-width: $md)
    padding: 15px

  button
    background: transparent
    border: none
    color: rgba(255,255,255,.5)
    padding: 10px 15px
    border-radius: 0

    &:hover
      color: #fff

    &.active
      background: #fff
      color: #3F4752
  h2
    font-size: 40px
    line-height: 45px
    margin-bottom: 65px
    font-weight: 400
    @media (max-width: $md)
      font-size: 28px
      line-height: 51px
      letter-spacing: -1px
      margin-bottom: 20px
      margin-left: 15px
  .img
    position: relative

    .show-project
      position: absolute
      left: 50%
      top: 50%
      border: 1px solid rgba(255, 255, 255, 0.4)
      font-size: 18px
      line-height: 22px
      color: #fff
      font-weight: 500
      padding: 16px 22px
      transform: translate(-50%, -50%)
      display: none
      z-index: 9

    // &:hover
    //   background: rgba(0, 0, 0, 0.6)
    @media (max-width: $md)
      height: 150px
    &:hover
      .show-project
        display: block
      &:after
        content: ""
        background: rgba(0, 0, 0, 0.6)
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
    &.large
      //height: calc(100% - 67px)
      height: 67vh
      @media (max-width: $md)
        height: 315px
    &.small
      height: calc(33.5vh - 33.5px)
      @media (max-width: $md)
        height: 315px
    .status
      position: absolute
      right: 0
      top: 0
      font-size: 15px
      font-weight: 500
      padding: 10px 15px
      @media (max-width: $md)
        font-size: 11px
      &.registration
        background: #6FB569
      &.sale
        background: #77B9D9
      &.done
        background: #576975
  .na-hradzi
    background: url("../assets/images/projects-na-hradzi.jpg") center/cover no-repeat
  .nadvorie
    background: url("../assets/images/bory5/01E_BH3B_static_ext_round_02.jpg") center/cover no-repeat
  .pri-jazierku
    background: url("../assets/images/projects-novy-dvor.jpg") center/cover no-repeat
  .pri-namesti
    background: url(../assets/images/bory-pri-namesti.jpg) center/cover no-repeat
  .bory1-img
    background: url(../assets/images/bory1/bory1.jpg) center/cover no-repeat
  .bory3-img
    background: url(../assets/images/projects-promenada.jpg) center/cover no-repeat
  .bory2-img
    background: url(../assets/images/bory2/bory2-home.jpg) center/cover no-repeat
  p
    margin: 15px 0 30px
    font-weight: 500
    a
      color: #fff
    @media (max-width: $md)
      font-size: 13px
</style>