import Vue from 'vue'
import Vuex from 'vuex'

import flats from './modules/flats.module'
import lang from './modules/lang.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    flats,
    lang
  }
})
