<template>
  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="col-12">
            <h3 v-html="$t('Najčastejšie otázky o&nbsp;štvrti&nbsp;Bory<br><br>')"></h3>
          </div>
          <div class="col-12 pt-3">
            <div class="accordion mb-4 mb-md-5" role="tablist">
              <div class="question-wrapper">
                <div v-b-toggle.accordion-1 class="question">{{ $t('faq_1_Q_1') }}</div>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_1')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-2 class="question">{{ $t('faq_1_Q_2') }}</div>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_2')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-3 class="question">{{ $t('faq_1_Q_3') }}</div>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_3')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-4 class="question">{{ $t('faq_1_Q_4') }}</div>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_4')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-5 class="question" v-html="$t('faq_1_Q_5')"></div>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_5')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-6 class="question" v-html="$t('faq_1_Q_6')"></div>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_6')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-7 class="question" v-html="$t('faq_1_Q_7')"></div>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_7')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-8 class="question" v-html="$t('faq_1_Q_8')"></div>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_8')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-9 class="question" v-html="$t('faq_1_Q_9')"></div>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_9')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-10 class="question" v-html="$t('faq_1_Q_10')"></div>
                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_10')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-11 class="question" v-html="$t('faq_1_Q_11')"></div>
                <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_11')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-12 class="question" v-html="$t('faq_1_Q_12')"></div>
                <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_12')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-13 class="question" v-html="$t('faq_1_Q_13')"></div>
                <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_1_A_13')"></div>
                </b-collapse>
              </div>
          </div>
        </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="col-12">
            <h3 v-html="$t('Najčastejšie otázky klientov, ktorí už v&nbsp;štvrti bývajú alebo čakajú na svoj byt')"></h3>
          </div>
          <div class="col-12 pt-3">
            <div class="accordion mb-4 mb-md-5" role="tablist">
              <div class="question-wrapper">
                <div v-b-toggle.accordion-15 class="question" v-html="$t('faq_2_Q_1')"></div>
                <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_1')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-16 class="question" v-html="$t('faq_2_Q_2')"></div>
                <b-collapse id="accordion-16" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_2')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-17 class="question" v-html="$t('faq_2_Q_3')"></div>
                <b-collapse id="accordion-17" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_3')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-18 class="question" v-html="$t('faq_2_Q_4')"></div>
                <b-collapse id="accordion-18" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_4')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-19 class="question" v-html="$t('faq_2_Q_5')"></div>
                <b-collapse id="accordion-19" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_5')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-20 class="question" v-html="$t('faq_2_Q_6')"></div>
                <b-collapse id="accordion-20" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_6')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-21 class="question" v-html="$t('faq_2_Q_7')"></div>
                <b-collapse id="accordion-21" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_7')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-22 class="question" v-html="$t('faq_2_Q_8')"></div>
                <b-collapse id="accordion-22" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_8')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-23 class="question" v-html="$t('faq_2_Q_9')"></div>
                <b-collapse id="accordion-23" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_9')"></div>
                </b-collapse>
              </div>
              <div class="question-wrapper">
                <div v-b-toggle.accordion-24 class="question" v-html="$t('faq_2_Q_10')"></div>
                <b-collapse id="accordion-24" accordion="my-accordion" role="tabpanel" class="answer">
                  <div v-html="$t('faq_2_A_10')"></div>
                </b-collapse>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="d-flex text px-5 px-md-4">
        <p v-html="$t('Máte otázku, na ktorú ste nenašli odpoveď?')"></p>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
name: "Faq"
}
</script>

<style lang="sass">
$md: 768px

.faq
  background: var(--textBright)
  padding: 70px 0

  .border-top-custom
    border-top: 1px solid rgba(255, 255, 255, 0.2)

  .text
    align-items: center
    justify-content: space-between
    width: 100%
    @media (max-width: $md)
      flex-direction: column
    p
     font-size: 16px
     line-height: 24px
     margin-bottom: 0
     @media (max-width: $md)
      margin-bottom: 15px

     a
      color: var(--blue)

  @media (max-width: $md)
    padding: 40px 0

  h3
    font-weight: 300
    font-size: 45px
    line-height: 50px

    @media (max-width: $md)
      font-size: 34px
      line-height: 38px
      margin-bottom: 20px

  .question-wrapper
    border-top: 1px solid rgba(255, 255, 255, 0.20)
    padding: 10px 0

    &:last-child
      border-bottom: 1px solid rgba(255, 255, 255, 0.20)

    @media (max-width: $md)
      padding: 10px 0

    .question
      padding: 15px 0
      font-size: 20px
      font-weight: 700
      line-height: 30px
      padding-left: 50px
      position: relative

      @media (max-width: $md)
        line-height: 24px

      &.not-collapsed
        &:before
          background-image: url(../assets/images/procare/minus.svg)

      &:before
        content: ""
        width: 32px
        height: 32px
        background-color: rgba(255, 255, 255, 0.05)
        background-image: url(../assets/images/procare/plus.svg)
        background-repeat: no-repeat
        background-position: center
        position: absolute
        left: 0
        top: 50%
        transform: translateY(-50%)

    .answer
      font-size: 16px
      font-weight: 400
      line-height: 24px
      padding-left: 50px

      @media (max-width: $md)
        padding-left: 0

      a
        color: var(--blue)
</style>