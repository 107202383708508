<template>
  <div>
    <section class="district p-3 pt-5">
      <div class="row align-items-center top pt-3 pt-md-5 mb-4">
        <div class="col-12 col-md-6 px-md-5 py-4">
          <h2 class="m-0">{{ $t('Vyrastá štvrť navrhnutá pre život. Buďte pri tom') }}</h2>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center">
          <p class="m-0 mb-3 mb-md-0">{{ $t('V Bratislave vzniká moderná štvrť so všetkým, na čo ste v meste zvyknutí. Od oddychu po šport, od gastronómie po kultúru. Buďte pri tom, čo tvorí pocit domova.') }}</p>
        </div>
      </div>
      <StvrtMap/>
    </section>

    <section class="highlights-block">
      <div class="block dark-bg">
        <div class="d-flex flex-wrap py-3">
          <div class="col-md-7 mb-4 mb-md-0">
            <!-- <video autoplay muted loop id="myVideo"> 
              <source type="video/mp4" src="/video/01_VIDEO_BORY_FINAL.mp4"/>
            </video> -->
            <div class="youtube-wrapper">
              <iframe src="https://www.youtube.com/embed/-UifQs_1XjY?si=C_LY5yPzn6IDGwpq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-md-5 d-flex text-block p-md-4">
            <h2 class="mb-5">{{ $t('Mestská štvrť so všetkým čo pre život potrebujete') }}</h2>
            <p v-html="$t('Na Boroch nájdete všetko čo potrebujete. Dostatok občianskej vybavenosti na to, aby ste nemuseli nikam chodiť. <br>Zabudnite na cestovanie za nákupmi, kaderníkom, škôlkou či ihriskami - všetko nájdete v bezprostrednom susedstve. Ušetrený časť môžete stráviť napríklad romantickou večerou na terase alebo hodinou v bazéne.')"></p>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="d-flex flex-wrap py-3">
          <div class="col-md-5 d-flex text-block p-md-4">
            <h2 class="mb-5" v-html="$t('Bývanie na dobrom<br> mieste')"></h2>
            <p>{{ $t('Bory nájdete len pár minút autom od centra mesta, pričom sa do štvrti viete dostať pohodlne aj MHD spojmi.') }}</p>
          </div>
          <div class="col-md-7 mb-4 mb-md-0">
            <img v-if="locale == 'sk'" src="../assets/images/bory_mapa_seda.png">
            <img v-if="locale == 'en'" src="../assets/images/mapa_seda_en.png">
            <img v-if="locale == 'ua'" src="../assets/images/bory_mapa_seda.png">
          </div>
        </div>
      </div>
      <div class="block dark-bg">
        <div class="d-flex flex-wrap py-3">
          <div class="col-md-7 mb-4 mb-md-0">
            <img src="../assets/images/stvrt-block-1.jpg">
          </div>
          <div class="col-md-5 d-flex text-block p-md-4">
            <h2 class="mb-5" v-html="$t('Verejný priestor <br>na úrovni')"></h2>
            <p>{{ $t('Veľkorysé verejné priestory v podobe námestí, ihrísk, parkov, bulváru či jazierka zaručia, že na Boroch sa nikdy nebudete nudit. Stačí sa vybrať na prechádzku a prejsť sa štvrťou, kde vás na každom rohu zaujme čosi nové.') }}</p>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="d-flex flex-wrap py-3">
          <div class="col-md-5 d-flex text-block p-md-4">
            <h2 class="mb-5" v-html="$t('Domov <br>obklopený zeleňou')"></h2>
            <p>{{ $t('Výhodou života v štvrti Bory je jej bezprostredný kontakt s prírodou a zelenými plochami. Malé Karpaty vám ponúkajú možnosti na turistiku, prechádzky, výlety či cyklotúry. A keby vám nebolo dosť, množstvo zelene postupne budujeme aj priamo v štvrti, vo forme verejne dostupných príležitostí na rekreáciu.') }}</p>
          </div>
          <div class="col-md-7 mb-4 mb-md-0">
            <img src="../assets/images/stvrt-block-2.jpg">
          </div>
        </div>
      </div>
    </section>

    <!--    TEMP BLOCK HERE -->

    <Projects></Projects>

    <section class="distrcit-sections pb-3">
      <div class="d-flex flex-wrap align-items-center top pt-4 mb-5">
        <div class="col-md-6 px-md-5 py-4">
          <h2 class="m-0">{{ $t('Pestrá mestská štvrť') }}</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0" v-html="$t('Aby bolo mesto funkčné, musí byť pestré.<br> Na Boroch rastú okrem obytnej zástavby aj obchody, škôlka či Nemocnica novej generácie.')"></p>
        </div>
      </div>
      <div class="d-flex flex-wrap px-0 px-md-2 pt-md-4">
        <div class="col-md-4">
          <div class="col-12 p-md-5 bg-col bg-col-1">
            <h3>{{ $t('Nemocnica novej generácie') }}</h3>
            <p>{{ $t('Špičkovo vybavené operačné sály, inovatívne procesy a kvalitná zdravotná starostlivosť na najvyššej európskej úrovni.') }}</p>
            <a href="https://nemocnica-bory.sk/" target="_blank" class="absolute-link"></a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-12 p-md-5 bg-col bg-col-2">
            <h3>{{ $t('Bory Mall') }}</h3>
            <p>{{ $t('Najväčšie bratislavské nákupné centrum s obrovským výberom obchodov aj zábavy.') }}</p>
            <a href="https://www.borymall.sk/sk/uvod" target="_blank" class="absolute-link"></a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-12 p-md-5 bg-col bg-col-3">
            <h3>{{ $t('Bory Nákupná zóna') }}</h3>
            <p>{{ $t('Nákupná zóna s veľkokapacitnými obchodnými prevádzkami od stavebnín a potravín až po nábytok.') }}</p>
          </div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import Projects from "@/components/Projects";
import StvrtMap from "@/components/maps/StvrtMap";
import store from "@/store";
export default {
  name: "stvrt",
  components: {
    StvrtMap,
    Projects,
    ContactForm
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    locale() {
      return store.state.lang.$locale
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.youtube-wrapper
  position: relative
  padding-bottom: 56.25%
  height: 0

  iframe
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
video
  max-width: 100%
.distrcit-sections
  background: var(--grey)
  .top
    p
      max-width: 640px
  .bg-col
    height: 800px
    position: relative
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      max-width: 310px
      @media (max-width: $md)
        font-size: 16px
        line-height: 21px
        max-width: 100%
    .absolute-link
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/nemocnica-bory-nove.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bb-block-2.jpg") center/cover no-repeat
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/stvrt-projekty-3.jpg") no-repeat
    background-size: cover
    background-position: -150px center
  .col-4
    padding-left: 8px
    padding-right: 8px
.highlights-block
  .block
    background: var(--grey)
    @media (max-width: $md)
      padding-bottom: 15px
    &.dark-bg
      background: var(--text-dark)
    img
      max-width: 100%
    h2
      font-size: 55px
      line-height: 60px
      font-weight: 300
      letter-spacing: -3px
    .text-block
      flex-direction: column
      p
        font-weight: 500
        max-width: 430px
        margin: 0
      h3
        margin: 0
        font-size: 40px
        line-height: 45px
        letter-spacing: -1px
        font-weight: 400
        @media (max-width: $md)
          font-size: 28px
          line-height: 40px
          letter-spacing: -1px
          margin-bottom: 15px
.home
  background: url("../assets/images/bory-stvrt-bg.jpg") bottom/cover no-repeat
  .intro-text
    p
      margin-bottom: 30px

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    max-width: 625px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>