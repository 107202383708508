<template>
  <div>
    <div>
      <div class="headline p-md-5 d-flex flex-wrap align-items-end" :style="'background: linear-gradient(0.52deg, rgba(33, 37, 43, 0.53) 14.72%, rgba(33, 37, 43, 0) 168.66%), url(' + article.headlingImage + ') center/cover'">
        <div class="text col-12 col-lg-9 pb-4 pb-md-3 pt-5 px-3 px-md-5">
          <div class="category">{{ $t(article.category) }}</div>
          <h1 class="m-0" v-html="article.title"></h1>
        </div>
        <div v-if="article.trip" class="icons col-lg-3 col-8">
          <div class="d-flex justify-content-center">
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Chodec.svg">
              <p>{{ article.peso }}</p>
            </div>
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Auto.svg">
              <p>{{ article.autom }}</p>
            </div>
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Bicykel.svg">
              <p>{{ article.bicyklom }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="article.trip" class="trip-icons container">
        <div class="d-flex flex-wrap align-items-end justify-content-center py-md-4 pt-4">
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/priroda/Priroda_' + article.priroda +'.svg'"></div>
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/historia/Historia_' + article.historia +'.svg'"></div>
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/aktivita/Aktivita_' + article.aktivita +'.svg'"></div>
        </div>
        <div v-if="article.trip" class="icons col-lg-3 col-8">
          <div class="d-flex justify-content-center">
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Chodec.svg">
              <p>{{ article.peso }}</p>
            </div>
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Auto.svg">
              <p>{{ article.autom }}</p>
            </div>
            <div class="col-4 text-center">
              <img class="mb-1" src="/news/vylety/icons/Bicykel.svg">
              <p>{{ article.bicyklom }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="article.trip" class="trip-icons container">
        <div class="d-flex flex-wrap align-items-end justify-content-center py-md-4 pt-4">
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/priroda/Priroda_' + article.priroda +'.svg'"></div>
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/historia/Historia_' + article.historia +'.svg'"></div>
          <div class="col-12 col-md-3 mb-3 mb-md-0"><img :src="'/news/vylety/aktivita/Aktivita_' + article.aktivita +'.svg'"></div>
        </div>
      </div>
      <div class="news m-auto py-4 py-md-5" :class="{'custom-article': article.custom}">
        <div class="mb-3 article-date">{{article.date}}</div>
        <div class="news-wrapper detail" v-html="article.html"></div>
      </div>
    </div>
    <NewsSlider></NewsSlider>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import NewsSlider from "@/components/NewsSlider";
import ContactForm from "@/components/ContactForm";
import news from "@/store/news.json"
import newsEn from "@/store/news_en.json"

export default {
  name: "NewsDetail",
  components: {ContactForm, NewsSlider},
  data() {
    return {
      articleId: this.$route.params.id,
      // news: news
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    document.title = this.article.title ? this.article.title + ' - Bory bývanie' : 'Novinky'
  },
  computed: {
    news() {
      if (this.$i18n.locale == 'en') {
        return newsEn
      } else {
        return news
      }
    },
    article() {
      return this.news.items.find(
        article => article.slug === this.articleId
      )
    }
  },
  watch:{
    $route (to){
      this.articleId = to.params.id
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.icons
  img
    max-width: 85%  

.icons
  img
    max-width: 85%  
h1
  font-size: 64px
  line-height: 60px
  letter-spacing: -3px
  font-weight: 900
  max-width: 980px
  @media (max-width: $md)
    max-width: 100%
    font-size: 44px
    line-height: 44px
    letter-spacing: -2px
.category
  font-size: 28px
  line-height: 32px
  letter-spacing: -0.5px
  margin-bottom: 30px
  @media (max-width: $md)
    font-size: 18px
    line-height: 22px
    margin-bottom: 15px
.headline
  height: 550px
  background-size: cover
  background-position: center
  @media (max-width: $md)
    height: 70vh
.news
  max-width: 680px
  color: var(--darkBlue)
  @media (max-width: $md)
    max-width: 100%
    padding: 0 16px
  p, h2
    color: var(--darkBlue)
  h2
    font-size: 28px
    line-height: 32px
    letter-spacing: -0.5px
    margin-bottom: 25px
    font-weight: 400
  p
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 18px
      line-height: 22px
      font-weight: 400
  .figure
    margin: 50px 0
  .figure-caption
    color: var(--grey)
    font-size: 15px
    line-height: 23px
    margin-top: 5px
</style>