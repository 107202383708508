<template>
  <div class="modal-body">
    <p class="smaller">
    </p><p>Welcome to www.borybyvanie.sk. Before you first start using our website, we recommend you carefully read the following rules for the processing of your personal data, which we follow anytime you provide them to us.</p>
    <ol>
      <li><strong>Introduction</strong>

        <p>Your privacy is important to us, which we why we strive to ensure a high level of protection for your personal data when they are processed, as well as to keep the processing of your data transparent in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of personal data and repealing Directive 95/25/EC (“GDPR”).</p>
        <p>For the purposes of the GDPR, the data controller determining the purpose and scope of the processing of personal data provided through any of this website’s functionalities is Penta Real Estate, s. r. o.,&nbsp;Digital Park II, Einsteinova 25, Bratislava 851 01, RN: 51&nbsp;284&nbsp;502, incorporated in the Bratislava I Business Register, Section: Sro, Insert No. 124856/B (“PRE” or “Data Controller PRE”). If you have any questions concerning the processing of your personal data that has been collected by us through any of this website’s functionalities or if you wish to exercise any of the rights you have under the GDPR below, please contact us by writing to the above address of Data Controller PRE or email GDPR_PRE_SR@pentarealestate.com.</p>
        <p>The joint controllers of the IS Marketing Group's information system are PRE and Penta Investments, s. r. o., Digital Park II, Einsteinova 25, Bratislava 851 01, RN: 47&nbsp;258 896, incorporated in the Bratislava I Business Register, Section: Sro, Insert No. 124855/B (“Joint Controllers”).</p>
      </li>
      <li><strong>Personal data collection</strong>

        <p>These rules apply to the processing of personal data we collect directly from you when you (a) complete the contact form; (b) request newsletters; (c) provide feedback; and (d) provide us with personal data over the telephone and in emails sent and received using the contact details this website has made available to you.</p>
        <p>We also collect cookies when you use this website. For more information, go to our <a v-b-modal.cookies href="#">Cookie Policy</a></p>
      </li>

      <li><strong>Personal data we process about you</strong>

        <p>The following personal data are processed for the purposes specified below in (4) of these rules:</p>
        Personal data of individuals interested in purchasing flats or in purchasing or leasing non-residential space in real estate developments from PRE; 
        Personal data of individuals interested in receiving information about current real estate offers from PRE; and 
        Personal data of individuals interested in contacting the Joint Controllers for marketing purposes.
        However, any personal data we collect is processed in accordance with the principle of minimizing the processing of personal data enshrined in the GDPR and so we process them only to the extent necessary and in the following scope: full name, email address, phone number.
        Neither special categories of personal data under the GDPR nor the personal data of persons under the age of 16 are subject to processing by us.
        It is crucial that the personal data we process about you is true and up-to-date. Please inform us if your personal data has changed since they began to be processed.
        Purposes for our processing of your personal data
        Your personal data is processed by us for the following purposes:
        To communicate with you, provide a response or feedback to an expression of interest or initiative taken by you through any of the website’s functionalities or the use of the contact details located on the website;
        For the Joint Operator’s lawful marketing purposes; and
        For the purposes of administering and protecting the website.
        If your personal data is processed by us for direct marketing purposes to send newsletters, you will always be given the option to unsubscribe from the newsletter at any time in the future if you are no longer interested in receiving it.
        Personal data processed for marketing purposes by the Joint Controllers are processed in IS Marketing's separate information system pursuant to an agreement between the Joint Controllers.
        Legal basis for the processing of your personal data
        The processing of your personal data for the purposes described in Point 4(a) above (communication based on your expressed interest) and for the purposes described in Point 4(b) (Joint Controllers’ marketing activities) is based on our legitimate interest in (i) actively communicating to anyone who expresses an interest in a property in our real estate portfolio and (ii) effectively marketing and promoting our business and what we offer from our real estate portfolio. Although our legitimate interests are the legal basis for processing your personal data, we will always give priority to your rights and interests over ours.
        If your personal data is processed for the purpose of administering and protecting the website, as described in Point 4(c), the legal basis for processing them is also the legitimate interest of providing IT services and security.
        The processing of your personal data on the basis of our legitimate interests is derived from Art. 6 (1) (f) of the GDPR. Nonetheless, you have the right to object at any time to the processing of your personal data. To do so, you may contact us at any time by writing to our registered address, which you will find above in the third paragraph of these rules, or by emailing GDPR_PRE_SR@pentarealestate.com. Any objection will be thoroughly assessed by us and we will inform you of the outcome from the assessment.
        Duration of the processing of your personal data
        Your personal data will be retained by us for as long as it is necessary to fulfill the purposes for collecting them.  The duration is three (3) years from the date when your personal data is collected. We regularly check for the expiry of the retention period for processing personal data and, upon expiration, they will cease to be processed by us and we will delete or destroy them.
        Sharing your personal data with third countries
        Your personal data is also processed by third parties in accordance with the purposes specified in these rules.
        Such third parties are service providers Data Controller PRE engages to process your personal data for the purposes mentioned in these rules. Specifically, these services cover technical and software support and the administration of marketing and sales by Data Controller PRE, companies through which Data Controller PRE develops, constructs and sells property in different real estate developments, and the Joint Controllers.
        A third party is Bory Home s. r. o., headquartered in Digital Park III. Einsteinova 25, Bratislava - Petržalka 851 01, RN: 48 099 171, incorporated in the Bratislava I Business Register, Section: Sro, Insert No. 103386/B, through which Data Controller PRE develops, constructs and sells property at Bory Bývanie and Bory Bývanie II.
        Another third party is Bory Home II s. r. o., headquartered in Digital Park III. Einsteinova 25, Bratislava - Petržalka 851 01, RN: 51 035 073, incorporated in the Bratislava I Business Register, Section: Sro, Insert No. 122328/B, through which Data Controller PRE develops, constructs and sells property at Bory Bývanie and Bory Bývanie II.
        Yet another third party is Media and Digital Services a.s., headquartered at Einsteinova 21, 851 01 Bratislava – Petržalka, Slovakia, RN: 48 238 902, incorporated in the Bratislava I Business Register, Section: Sa, Insert No. 6196/B, which manages marketing for Data Controller PRE.
        A company providing web support and marketing communication is also involved in the processing of your personal data as a sub-processor.
        The companies below are our processors for cookies, where consent is not required under the Cookie Policy (see link above):
        Google Ireland Ltd., Gordon House Barrow Street, Dublin 4, DO4E5W5, Ireland, CRO: 368047, in connection with the processing of analytics cookies via Google Analytics;
        Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland, CRO: 462932, in connection with the processing of analytics cookies via Facebook Pixel.
        Data Controller PRE has entered into personal data processing contracts with the processors.
        Both the processors and sub-processors are obliged to process your personal data solely for the purposes described in these rules. Your data will not be disclosed to third parties for independent use or processing beyond the purposes mentioned in these rules.
        International transfer of your personal data
        The software system processing your personal data under a license agreement between Data Controller PRE and the software system provider is stored in the latter’s servers located in the United Kingdom of Great Britain and Northern Ireland.
        Your rights in relation to the processing of your personal data
        The GDPR grants you a significant number of rights, which we respect and will allow you to exercise to the fullest extent.  When exercising your rights, you are particularly entitled:
        To request a copy of the personal data we hold about you and a description of how we use them;
        To request the rectification of personal data we hold about you if you believe that the personal data may be inaccurate, out of date and/or incorrect;
        To request the erasure of personal data we process about you if the data is processed by us on the basis of your consent;
        To request the disclosure of your personal data we process about you by automated means, based on your consent to a third party;
        To request in certain limited circumstances that we restrict the use of your personal data or the purpose for which it is processed;
        To object in certain circumstances to the processing of your personal data since we process them on the basis of our own legitimate interests.
        If you would like more information about your rights under the GDPR, which you can exercise against us in relation to the processing of your personal data, please don't hesitate to contact us by writing to our registered address, which you will find above in the third paragraph of these rules or emailing GDPR_PRE_SR@pentarealestate.com.
        It is in our interest to reply to any questions you may have and to resolve any complaints about the processing of your personal data. However, if you feel that your complaint has not been resolved, please note that in Slovakia you have the right to submit a complaint to the Office for Personal Data Protection.
        These rules for the processing of personal data may be unilaterally amended at any time.
        Penta Real Estate, s.r.o.
        Penta Investments, s. r. o
    <p></p>
    </li>
    </ol>
  </div>
</template>

<script>
export default {
name: "AgreementsModalEN"
}
</script>

<style scoped>

</style>