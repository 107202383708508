import Vue from 'vue'
import App from './App.vue'
import VueGtm from "vue-gtm"
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import 'slick-carousel/slick/slick.css'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import getCookie from './plugins/cookie-banner/getCookie'
//Styles
import '@/assets/theme/theme.sass'
// import WAnalytics from '@/plugins/analytics/index'
import WAnalytics2 from '@/plugins/analytics2.0/index'
import Hotjar from '@/plugins/hotjar/index'
import VueI18n from 'vue-i18n'
import en from './locales/sk.json'

import { languages } from './plugins/w/translate/index.js'
import { defaultLocale } from './plugins/w/translate/index.js'
const messages = Object.assign(languages)

function getCookies(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}


WAnalytics2.addGoogleAnalytics(router)
WAnalytics2.addGoogleTagManager(router)
Hotjar.addHotjar()


// if(getCookie.getCookie() && getCookie.getCookie().cookie_analytic) {
//   WAnalytics2.addGoogleAnalytics(router)
//   WAnalytics2.addGoogleTagManager(router)
//   Hotjar.addHotjar()
// }

// if(getCookie.getCookie() && getCookie.getCookie().cookies_marketing) {
//   // WAnalytics.addFacebookPixel(router)
// }


Vue.prototype.$locale = getCookies('locale')


Vue.use(BootstrapVue)
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 99999
    }
})

Vue.use(VueI18n)

import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'sk',
  messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
