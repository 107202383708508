import en from './locales/en.json'
import sk from './locales/sk.json'
import ua from './locales/ua.json'

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const defaultLocale = getCookie('locale') ? getCookie('locale') : 'sk'

export const languages = {
  en: en,
  sk: sk,
  ua: ua,
}

