<template>
  <div>
    <section class="news-section">
      <div class="slider px-3 px-md-5">
        <slick
            ref="slick"
            :options="slickOptions"
        >
          <div class="slide d-flex flex-wrap" v-for="(item, idx) in news.items.slice(0, 2)" :key="idx">
            <div class="col-md-4 p-md-5 slide-text">
              <div class="category">{{ $t(item.category) }}</div>
              <h2 v-html="item.title"></h2>
              <p class="mb-5 perex">{{item.perex}}</p>
              <div class="more-link">{{ $t('Zobraziť článok') }}</div>
            </div>
            <div class="col-md-8 img-bg" :style="'background: url(' + item.headlingImage + ') center/cover'"></div>
            <b-link class="detail-link" :to="'/novinky/' + item.slug"></b-link>
          </div>
        </slick>
      </div>
      <div class="news-wrapper p-4 p-md-5">
        <div class="category-filter">
          <div class="mb-4 text-center news-category-filter">
            <button class="btn btn-secondary m-2" @click="filter('show all')" :class="['show all'===filterOption? 'btn-primary' : '']">{{ $t('Všetky') }}</button>
            <button class="btn btn-secondary m-2" @click="filter('o-projektoch')" :class="['o-projektoch'===filterOption? 'btn-primary' : '']">{{ $t('O projektoch') }}</button>
            <button class="btn btn-secondary m-2" @click="filter('zo-stvrte')" :class="['zo-stvrte'===filterOption? 'btn-primary' : '']">{{ $t('Zo štvrte') }}</button>
            <button class="btn btn-secondary m-2" @click="filter('zaujimavosti')" :class="['zaujimavosti'===filterOption? 'btn-primary' : '']">{{ $t('Zaujímavosti') }}</button>
            <button class="btn btn-secondary m-2" @click="filter('kam-v-okoli')" :class="['kam-v-okoli'===filterOption? 'btn-primary' : '']">{{ $t('Kam v okolí') }}</button>
            <button class="btn btn-secondary m-2" @click="filter('kam-v-okoli')" :class="['kam-v-okoli'===filterOption? 'btn-primary' : '']">Kam v okolí</button>
          </div>
        </div>
        <div class="asd">
          <isotope ref="cpt" id="root_isotope1" :item-selector="'element-item'" :list="news.items" :options='option' @filter="filterOption=arguments[0]">

            <div class="news" v-for="(item, idx) in news.items" :key="idx" :class='[item.filterCategory]'>
              <router-link class="link-to-detail" :to="'/novinky/' + item.slug">link</router-link>
              <div class="image">
                <img :src="item.thumbImage" alt="">
              </div>
              <div class="text">
                <div class="category">{{ $t(item.category) }}</div>
                <h3 class="title" v-html="item.title"></h3>
                <p class="mb-4 perex" v-html="item.perex"></p>
              </div>
              <div class="more-link">{{ $t('Zobraziť článok') }}</div>
            </div>
          </isotope>
          
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import Slick from "vue-slick";
import news from "@/store/news.json"
import newsEn from "@/store/news_en.json"
import isotope from 'vueisotope'

export default {
  name: "News",
  components: {
    ContactForm,
    Slick,
    isotope
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500
        // Any other options that can be got from plugin documentation
      },
      // news: news,
      filterOption: "show all",
      option: {
        itemSelector: ".element-item",
        getFilterData: {
          "show all": function() {
            return true;
          },
          'o-projektoch': function(el) {
            return el.filterCategory === "o-projektoch";
          },
          'zo-stvrte': function(el) {
            return el.filterCategory === "zo-stvrte";
          },
          'zaujimavosti': function(el) {
            return el.filterCategory === "zaujimavosti";
          },
          'kam-v-okoli': function(el) {
            return el.filterCategory === "kam-v-okoli";
          }
        }
      }
    };
  },
  computed: {
    news() {
      if (this.$i18n.locale == 'en') {
        return newsEn
      } else {
        return news
      }
    }
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },

    filter: function(key) {
      this.$refs.cpt.filter(key)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.element-item
  width: calc(25% - 30px)
  height: 20vw
  float: left
  margin: 15px
  position: relative

  @media (max-width: 1020px)
    width: 100%
    height: auto
    margin: 0
    margin-bottom: 25px
.slide
  position: relative

  &:hover
    .img-bg
      position: relative
      &:before
        content: ""
        width: 100%
        height: 100%
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))
        position: absolute
        left: 0
        top: 0


    .slide-text
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3F4752

      @media (max-width: $md)
        background: transparent
    .more-link
      &:after
        right: -20px
  .detail-link
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 9
  .img-bg
    background: url(../assets/images/news/news-slider-bg.jpg) center/cover no-repeat

    &:before
      transition: all .3s ease
    @media (max-width: $md)
      &:after
        content: ""
        width: 100%
        height: 100%
        background: linear-gradient(15.27deg, rgba(0, 0, 0, 0.7) 25.96%, rgba(0, 0, 0, 0) 80.78%)
        position: absolute
        left: 0
        top: 0
    @media (max-width: $md)
      height: 40vh
  .more-link
    position: relative
    display: inline-block

    &:after
      content: ""
      width: 5px
      height: 9px
      background: url(../assets/icons/arrow.png) no-repeat
      background-size: contain
      position: absolute
      right: -15px
      top: 8px
      transition: all .3s ease
  .slide-text
    background: var(--textBright)
    transition: all .3s ease
    @media (max-width: $md)
      padding-top: 5px
      padding-bottom: 15px
      position: absolute
      bottom: 0
      left: 0
      z-index: 99
      background: transparent
    .category
      font-size: 16px
      line-height: 21px
      margin-bottom: 15px
      font-weight: 500
      margin-top: 20px
      color: #77B9D9
      @media (max-width: $md)
        font-size: 14px
        line-height: 18px
    h2
      font-size: 40px
      line-height: 45px
      letter-spacing: -1px
      font-weight: 400
      max-width: 390px
      margin-bottom: 15px
    p
      font-weight: 500
      max-width: 400px
      @media (max-width: $md)
        display: none
        font-size: 18px
        line-height: 22px
.news-section
  background: var(--darkBlue)
  padding-top: 150px
  @media (max-width: $md)
    padding-top: 80px
.news-wrapper
  padding-bottom: 100px !important

  @media (max-width: $md)
    padding-left: 15px!important
    padding-right: 15px!important
  .news
    position: relative
    .image
      position: relative
      overflow: hidden
      max-height: 100%

      img
        @media (max-width: $md)
          transform: translateY(-25%)

      @media (max-width: 1020px)
        height: 50vw
      &:after
        content: ""
        width: 100%
        height: 100%
        background: linear-gradient(13.45deg, rgba(33, 37, 43, 0.7) 29.2%, rgba(33, 37, 43, 0) 62.19%)
        position: absolute
        left: 0
        top: 0

        @media (max-width: $md)
          display: none
    &:hover
      .image
        &:after
          background: rgba(33, 37, 43, 0.6)
      .text
        bottom: 70px

      .more-link
        &:after
          right: -20px
    .link-to-detail
      position: absolute
      width: 100%
      height: 100%
      text-indent: -9999px
      left: 0
      top: 0
      z-index: 999
    @media (max-width: $md)
      padding: 0.5rem
    .more-link
      position: absolute
      left: 30px
      bottom: 20px

      @media (max-width: $md)
        display: inline-block
        position: relative
        left: auto
        bottom: auto

      &:after
        content: ""
        width: 5px
        height: 9px
        background: url(../assets/icons/arrow.png) no-repeat
        background-size: contain
        position: absolute
        right: -15px
        top: 8px
        transition: all .3s ease
    .text
      position: absolute
      left: 30px
      bottom: 50px
      height: 100px
      transition: all .3s ease
      @media (max-width: 1020px)
        position: static
        height: auto
      .category
        color: #77B9D9
        font-size: 16px
        line-height: 26px
        letter-spacing: -0.3px
        font-weight: 500
        margin-bottom: 5px
        @media (max-width: $md)
          font-size: 13px
          line-height: 26px
          letter-spacing: -0.3px
      .title
        font-size: 20px
        line-height: 24px
        letter-spacing: -0.5px
        font-weight: 500
        padding-right: 20px
      .perex
        display: none
        @media (max-width: $md)
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 3
          -webkit-box-orient: vertical

.category-filter
  position: relative
  @media (max-width: $md)
    &::after
      content: ""
      width: 98px
      height: 100%
      background: linear-gradient(270deg, #21252B 20.13%, rgba(33, 37, 43, 0) 100%)
      position: absolute
      right: -15px
      top: 0
      pointer-events: none
      z-index: 999
.news-category-filter
  @media (max-width: $md)
    display: flex
    flex-wrap: nowrap
    overflow-x: auto
    -webkit-overflow-scrolling: touch
    padding-right: 50px

    &::-webkit-scrollbar
      display: none

    button
      width: auto
      flex: 0 0 auto
      margin-left: 0 !important
      margin-right: 8px !important
</style>