<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>{{ $t('Štvrť novej generácie') }}</h1>
          <p v-html="$t('Štvrť so všetkým čo pre život potrebujete.')"></p>
          <b-button to="/byty" squared variant="primary">{{ $t('Pozrieť byty') }}</b-button>
        </div>
      </div>
      <div id="fixed-info">
        <div class="box">
          <div class="big">- 3 %</div>
          <p class="border-bottom-white"></p>
          <div>
            <p class="text-dark"><a href="../znizeny-urok" class="link" v-html="$t('Získajte na Bory Bývanie úrok nižší o&nbsp;3 %')"></a></p>
          </div>
        </div>
      </div>
    </div>
    <TempBlock/>
    <HomeMapNew />
    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7">
            <div class="youtube-wrapper">
              <iframe src="https://www.youtube.com/embed/pe6jN_I_wJw?si=E3IRv_7ggGBq2FcU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-md-5 col-p0 px-md-5 py-3">
            <p v-html="$t('Čo nájdem na Boroch')"></p>
            <h2 v-html="$t('Bývanie pri tom,<br> na čom záleží')"></h2>
            <p v-html="$t('Bory sme navrhli tak, aby ste relax aj nevyhnutnosti vybavili pešo. V bezprostrednom okolí vášho bývania nájdete ihriská, obchody, reštaurácie aj nemocnicu. To všetko spolu vo veľkorysom verejnom priestore plnom možností na to, čo radi robíte.')"></p>
          </div>
        </div>
        <div class="row px-md-2">
          <div class="col-md-4">
            <div class="col-md-12 p-md-5 bg-col bg-col-1">
              <h3 v-html="$t('Ranný beh aj večerný<br> basketbal')"></h3>
              <p v-html="$t('Záleží nám na tom, aby ste mali kde zamakať aj vydýchnuť. Na Boroch vás čakajú tisíce m<sup>2</sup> zelených plôch, v ktorých si môžete oddýchnuť alebo zašportovať.')"></p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3 v-html="$t('Nákupné centrum aj nemocnica')"></h3>
              <p>{{ $t('Na Boroch navštívite kaderníka aj lekára, dáte si pracovný obed alebo sa prejdete s rannou kávou pri jazierku. Pohodlne zájdete na poštu, ale aj s deťmi do škôlky. Buďte pri tom, ako ostatní čakajú v zápchach, kým vy už sedíte s kávou na terase.') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3 v-html="$t('Autom<br> aj električkou')"></h3>
              <p v-html="$t('Nestrácajte čas hľadaním parkovacích miest. Na Boroch pohodlne zaparkujete, ľahko sa pripojíte na diaľnicu a vďaka električke, ktorá bude prechádzať štvrťou cez ústredný mestský bulvár, sa do centra dostanete za 20 minút.')"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="gallery">
      <div class="gallery-container" v-viewer="{movable: false, zoomable: false, title: false, rotatable: false, scalable: false}">
        <div class="gallery-text pl-md-4 pt-4">
          <h3>{{ $t('Galéria štvrte') }}</h3>
          <p class="mb-5" v-html="$t('Nahliadnite do štvrte Bory a prezrite si galériu <br>hotových etáp aj aktuálny stav ďalšej výstavby.')"></p>
          <a href="/galeria" class="btn btn-primary rounded-0 py-3">{{ $t('Zobraziť galériu') }}</a>
        </div>
        <div class="d-none d-md-block img img-1 large">
          <img src="../assets/images/gallery-2.jpg" alt="Bory Byvanie">
        </div>
        <div class="d-none d-md-block img img-2">
          <img src="../assets/images/gallery-1.jpg" alt="">
        </div>
        <div class="d-none d-md-block img img-3 small">
          <img src="../assets/images/glry-3.jpg" alt="">
        </div>
        <div class="d-none d-md-block img img-4 small">
          <img src="../assets/images/glry-4.jpg" alt="">
        </div>
        <div class="d-none d-md-block img img-5 medium">
          <img src="../assets/images/glry-5.jpg" alt="">
        </div>
        <div class="d-none d-md-block img img-6 medium">
          <img src="../assets/images/glry-6.jpg" alt="">
        </div>

      </div>
      <section class="gallery-mobile d-block d-md-none">
        <slick
            ref="slick2"
            :options="gallerySlick"
            v-viewer="{ inline: false, button: true, navbar: false, title: false, toolbar: true, tooltip: false, movable: false, zoomable: false, rotatable: false, scalable: false, transition: false, fullscreen: true, keyboard: true }"
        >
          <div class="row d-flex mx-0">
            <div class="col-12 px-0 mb-2">
              <div class="img img-1">
                <img src="../assets/images/gallery-2.jpg" alt="Bory Byvanie">
              </div>
            </div>
            <div class="col-6 pr-1 pl-0">
              <div class="img img-2">
                <img src="../assets/images/gallery-1.jpg" alt="">
              </div>
            </div>
            <div class="col-6 pl-1 pr-0">
              <div class="img img-3">
                <img src="../assets/images/glry-3.jpg" alt="">
              </div>
            </div>
          </div>
          <div class="row d-flex mx-0">
            <div class="col-6 pr-1 pl-0">
              <div class="img img-4">
                <img src="../assets/images/glry-4.jpg" alt="">
              </div>
            </div>
            <div class="col-6 pl-1 pr-0">
              <div class="img img-5">
                <img src="../assets/images/glry-5.jpg" alt="">
              </div>
            </div>
            <div class="col-12 px-0 mt-2">
              <div class="img img-6">
                <img src="../assets/images/glry-6.jpg" alt="Bory Byvanie">
              </div>
            </div>
          </div>
        </slick>
      </section>
    </section>

    

    <Projects></Projects>

    <NewsSlider></NewsSlider>

    <Faq/>

    <ContactForm></ContactForm>

  </div>
</template>

<script>
// @ is an alias to /src
import Slick from "vue-slick";
import ContactForm from "@/components/ContactForm";
import NewsSlider from "@/components/NewsSlider";
import Projects from "@/components/Projects";
import HomeMapNew from "@/components/maps/HomeMapNew";
import TempBlock from "@/components/TempBlock";
import Faq from "@/components/Faq";

export default {
  name: 'Home',
  components: {
    Slick,
    TempBlock,
    HomeMapNew,
    Projects,
    ContactForm,
    NewsSlider,
    Faq
  },

  data() {
    return {
      gallerySlick: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        arrows: false
      },
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

.youtube-wrapper
  position: relative
  padding-bottom: 56.25%
  height: 0

  iframe
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

.gallery
  background: #21252B
  padding: 20px
  .gallery-container
    display: grid
    grid-template-columns: repeat(6, 1fr)
    //grid-template-rows: repeat(5, 1fr)
    grid-column-gap: 20px
    grid-row-gap: 20px
    @media (max-width: $md)
      display: flex
      flex-wrap: wrap
      grid-template-columns: unset
      grid-row-gap: unset
      grid-column-gap: unset
    .img
      position: relative
      cursor: pointer
      &:hover
        &:after
          content: ""
          width: 100%
          height: 100%
          background: rgba(0, 0, 0, 0.6)
          position: absolute
          left: 0
          top: 0
          pointer-events: none
        &:before
          content: ""
          width: 24px
          height: 24px
          background: url(../assets/images/plus-icon.svg) no-repeat
          position: absolute
          left: 50%
          top: 50%
          transform: translate(-50%)
          z-index: 2
          pointer-events: none
          @media (max-width: $md)
            width: 20px
            height: 20px
            background-size: cover
      @media (max-width: $md)
        margin-bottom: 16px
      
    .gallery-text
      // position: absolute
      // left: 40px
      // top: 40px
      // max-width: 530px
      grid-area: 1 / 1 / 3 / 5
      @media (max-width: $md)
        margin-bottom: 20px
      h3
        font-size: 55px
        line-height: 60px
        font-weight: 400
        letter-spacing: -1px
        margin-bottom: 15px

        @media (max-width: $md)
          font-size: 40px
      p
        font-weight: 500
        max-width: 550px
    .img-1
      grid-area: 1 / 5 / 3 / 7
      background: url(../assets/images/gallery-2.jpg) center/cover no-repeat
      position: relative
      @media (max-width: $md)
        width: 100%
        height: 50vh
    .img-2
      grid-area: 3 / 1 / 6 / 5
      background: url(../assets/images/gallery-1.jpg) center/cover no-repeat
      @media (max-width: $md)
        width: 48%
        margin-right: 2%
    .img-3
      grid-area: 3 / 5 / 6 / 7
      background: url(../assets/images/glry-3.jpg) center/cover no-repeat
      @media (max-width: $md)
        width: 48%
        margin-left: 2%
    .img-4
      grid-area: 6 / 1 / 6 / 3
      background: url(../assets/images/glry-4.jpg) center/cover no-repeat
    .img-5
      grid-area: 6 / 3 / 6 / 5
      background: url(../assets/images/glry-5.jpg) center/cover no-repeat
    .img-6
      grid-area: 6 / 5 / 6 / 7
      background: url(../assets/images/glry-6.jpg) center/cover no-repeat
    img
      display: block
      width: 100%
      opacity: 0
      position: relative
      height: 100%

.gallery-mobile
  .img
    height: 30vh
  .img-1
    background: url(../assets/images/gallery-2.jpg) center/cover no-repeat
    position: relative
  .img-2
    background: url(../assets/images/gallery-1.jpg) center/cover no-repeat
  .img-3
    background: url(../assets/images/glry-3.jpg) center/cover no-repeat
  .img-4
    background: url(../assets/images/glry-4.jpg) center/cover no-repeat
  .img-5
    background: url(../assets/images/glry-5.jpg) center/cover no-repeat
  .img-6
    background: url(../assets/images/glry-6.jpg) center/cover no-repeat
  img
    display: block
    width: 100%
    opacity: 0
    position: relative
    height: 100%


.home
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/images/HOMEPAGE.jpg") center/cover no-repeat
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), url("../assets/images/Novy_Dvor_zima.jpg") center/cover no-repeat
  .intro-text
    p
      max-width: 640px
      margin-bottom: 30px


.interactive-map
  //height: 92vh
  position: relative
  .overlay
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: url("../assets/images/mapoverlay2.png") no-repeat
    background-size: cover
.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .col-p0
    @media (max-width: $md)
      padding: 0
  .bg-img
    background: url("../assets/images/bory-okolie.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    letter-spacing: -3px
    margin-left: -4px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    max-width: 320px
    font-weight: 500
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory-playground2.jpg") center/cover no-repeat
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory-nemocnica-new.jpg") center/cover no-repeat
    @media (max-width: $md)
      background-position: top
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/bory-doprava.jpg") center/cover no-repeat
  .col-md-4
    padding-left: 8px
    padding-right: 8px

</style>
