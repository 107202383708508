<template>
  <div class="dark-bg">
    <!-- <section class="gallery-slider">
      <slick
          ref="slick-top"
          :options="slickOptions"
      >
        <div class="gallery-slide slide-1"></div>
        <div class="gallery-slide slide-2"></div>
        <div class="gallery-slide slide-3"></div>
        <div class="gallery-slide slide-4"></div>
        <div class="gallery-slide slide-5"></div>
        <div class="gallery-slide slide-6"></div>
      </slick>
    </section> -->

    <div class="gallery">
      <div class="navs-gallery d-flex align-items-center">
        <div class="gallery-tab d-flex align-items-center b1">
          <b-link @click="b1Gallery = true; b2Gallery = false; b3Gallery = false; b4Gallery = false; b5Gallery = false; showGallery(b1exterier)" :class="{active: b1Gallery == true}"><img src="../assets/images/gallery-slides/bory1_small.png" alt="Bory 1 gallery"></b-link>
        </div>
        <div class="gallery-tab d-flex align-items-center b1">
          <b-link @click="b2Gallery = true; b1Gallery = false; b3Gallery = false; b4Gallery = false; b5Gallery = false; showGallery(b2exterier)" :class="{active: b2Gallery == true}"><img src="../assets/images/gallery-slides/bory2_small.png" alt="Bory 2 gallery"></b-link>
        </div>
        <div class="gallery-tab d-flex align-items-center b1">
          <b-link @click="b3Gallery = true; b1Gallery = false; b2Gallery = false; b4Gallery = false; b5Gallery = false; showGallery(b3exterier)" :class="{active: b3Gallery == true}"><img src="../assets/images/gallery-slides/bory3_small.png" alt="Bory 3 gallery"></b-link>
        </div>
        <div class="gallery-tab d-flex align-items-center b1">
          <b-link @click="b4Gallery = true; b1Gallery = false; b2Gallery = false; b3Gallery = false; b5Gallery = false; showGallery(b4exterier)" :class="{active: b4Gallery == true}"><img src="../assets/images/na-hradzi.png" alt="Na Hrádzi gallery"></b-link>
        </div>
        <div class="gallery-tab d-flex align-items-center b1">
          <b-link @click="b5Gallery = true; b1Gallery = false; b2Gallery = false; b3Gallery = false; b4Gallery = false; showGallery(b5exterier)" :class="{active: b5Gallery == true}"><img src="../assets/images/nadvorie_logo.png" alt="Nádvorie gallery"></b-link>
        </div>
      </div>
      <div class="sub-navs-gallery">
        <div v-if="b1Gallery">
          <h3>Bory Prvé Domy</h3>
          <div class="stage-nav">
            <b-link @click="showGallery(b1vystavba); selectActive('b1vystavba')" :class="{active: activeLink == 'b1vystavba'}" class="text-link">{{ $t('Výstavba') }}</b-link>
            <b-link @click="showGallery(b1interier); selectActive('b1interier')" :class="{active: activeLink == 'b1interier'}" class="text-link">{{ $t('Interiér') }}</b-link>
            <b-link @click="showGallery(b1exterier); selectActive('b1exterier')" :class="{active: activeLink == 'b1exterier' || activeLink == ''}" class="text-link">{{ $t('Exteriér') }}</b-link>
          </div>
        </div>
        <div v-if="b2Gallery">
          <h3>Bory Nový Dvor</h3>
          <div class="stage-nav">
            <b-link @click="showGallery(b2vystavba); selectActive('b2vystavba')" :class="{active: activeLink == 'b2vystavba'}" class="text-link">{{ $t('fáza') }} 1</b-link>
            <b-link @click="showGallery(faza2); selectActive('faza2')" :class="{active: activeLink == 'faza2'}" class="text-link">{{ $t('fáza') }} 2</b-link>
            <b-link @click="showGallery(b2interier); selectActive('b2interier')" :class="{active: activeLink == 'b2interier'}" class="text-link">{{ $t('Interiér') }}</b-link>
            <b-link @click="showGallery(b2exterier); selectActive('b2exterier')" :class="{active: activeLink == 'b2exterier' || activeLink == ''}" class="text-link">{{ $t('Exteriér') }}</b-link>
          </div>
        </div>
        <div v-if="b3Gallery">
          <h3>Bory Promenáda</h3>
          <div class="stage-nav">
            <b-link @click="showGallery(b3vystavba); selectActive('b3vystavba')" :class="{active: activeLink == 'b3vystavba'}" class="text-link">{{ $t('Výstavba') }}</b-link>
            <b-link @click="showGallery(b3interier); selectActive('b3interier')" :class="{active: activeLink == 'b3interier'}" class="text-link">{{ $t('Interiér') }}</b-link>
            <b-link @click="showGallery(b3exterier); selectActive('b3exterier')" :class="{active: activeLink == 'b3exterier' || activeLink == ''}" class="text-link">{{ $t('Exteriér') }}</b-link>
          </div>
        </div>
        <div v-if="b4Gallery">
          <h3>Bory Na Hrádzi</h3>
          <div class="stage-nav">
            <b-link @click="showGallery(b4vystavba); selectActive('b4vystavba')" :class="{active: activeLink == 'b4vystavba'}" class="text-link">{{ $t('Výstavba') }}</b-link>
            <b-link @click="showGallery(b4interier); selectActive('b4interier')" :class="{active: activeLink == 'b4interier'}" class="text-link">{{ $t('Interiér') }}</b-link>
            <b-link @click="showGallery(b4exterier); selectActive('b4exterier')" :class="{active: activeLink == 'b4exterier' || activeLink == ''}" class="text-link">{{ $t('Exteriér') }}</b-link>
          </div>
        </div>
        <div v-if="b5Gallery">
          <h3>Bory Nádvorie</h3>
          <div class="stage-nav">
            <b-link @click="showGallery(b5interier); selectActive('b4interier')" :class="{active: activeLink == 'b5interier'}" class="text-link">{{ $t('Interiér') }}</b-link>
            <b-link @click="showGallery(b5exterier); selectActive('b4exterier')" :class="{active: activeLink == 'b5exterier' || activeLink == ''}" class="text-link">{{ $t('Exteriér') }}</b-link>
          </div>
        </div>
      </div>
      <div class="gallery-content">
        <slick
            ref="slick2"
            :options="gallerySlick"
            v-if="images"
            v-viewer="{ inline: false, button: true, navbar: false, title: false, toolbar: true, tooltip: false, movable: false, zoomable: false, rotatable: false, scalable: false, transition: false, fullscreen: true, keyboard: true }"
        >
          <div class="images" v-for="(imageArrays, idx) in images" :key="idx">
            <div :data-src="image" v-for="image in imageArrays" :key="image" class="photo" :style="'background: url(' + image + ') center/cover'">
                <img :src="image" alt="">
            </div>
          </div>
        </slick>
      </div>
    </div>

    <NewsSlider></NewsSlider>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import Slick from "vue-slick";
import ContactForm from "@/components/ContactForm";
import NewsSlider from "@/components/NewsSlider";
export default {
name: "Gallery",
  components: {Slick, NewsSlider, ContactForm},
  data() {
    return {
      b1Gallery: true,
      b2Gallery: false,
      b3Gallery: false,
      b4Gallery: false,
      b5Gallery: false,
      activeLink: 'b1exterier',
      gallerySlick: {
        slidesToShow: 1,
        infinite: false,
        dots: true,
        arrows: false
      },
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500
        // Any other options that can be got from plugin documentation
      },
      selectedTab: 0,
      b1exterier: [
          [
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-1.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-2.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-3.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-4.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-5.jpg'
          ],
          [
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-6.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-7.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-8.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-9.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-10.jpg'
          ],
          [
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-11.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-12.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-13.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-14.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-15.jpg'
          ],
          [
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-16.jpg',
            '/gallery/bory1/exterier/Bory-Byvanie-1-exterier-17.jpg'
          ]
      ],
      b1interier: [
        [
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-1.jpg',
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-2.jpg',
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-3.jpg',
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-4.jpg',
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-5.jpg'
        ],
        [
          '/gallery/bory1/interier/Bory-Byvanie-1-interier-6.jpg'
        ]
      ],
      b1vystavba: [
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-1.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-2.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-3.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-4.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-5.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-6.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-7.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-8.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-9.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-10.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-11.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-12.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-13.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-14.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-15.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-16.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-17.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-18.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-19.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-20.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-21.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-22.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-23.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-24.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-25.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-26.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-27.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-28.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-29.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-30.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-31.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-32.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-33.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-34.jpg',
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-35.jpg'
        ],
        [
          '/gallery/bory1/vystavba/Bory-Byvanie-1-vystavba-36.jpg'
        ]
      ],
      b2exterier: [
        [
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-1.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-2.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-3.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-4.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-5.jpg'
        ],
        [
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-6.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-7.jpg',
          '/gallery/bory2/exterier/Bory-Byvanie-2-exterier-8.jpg'
        ]
      ],
      b2interier: [
        [
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-7.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-8.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-1.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-2.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-6.jpg'
        ],
        [
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-5.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-3.jpg',
          '/gallery/bory2/interier/Bory-Byvanie-2-interier-4.jpg',
        ]
      ],
      b2vystavba: [
        [
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1013.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1011.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1009.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1008.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1007.jpg'
        ],
        [
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1006.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1004.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1003.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1002.jpg',
          '/gallery/bory2/faza2/BH2B-SNEH-1-lowres-1001.jpg'
        ],
        [
          '/gallery/bory2/vystavba/2021_07BH2A-RANO-lowres-1025.jpg',
          '/gallery/bory2/vystavba/2021_07BH2A-RANO-lowres-1022.jpg',
          '/gallery/bory2/vystavba/2021_07BH2A-RANO-lowres-1020.jpg',
          '/gallery/bory2/vystavba/2021_07BH2A-RANO-lowres-1008.jpg',
          '/gallery/bory2/vystavba/2021_07BH2A-RANO-lowres-1001.jpg',
        ],
        [
          '/gallery/bory2/vystavba/2021_03BH2-SUNSET-lowres-1030.jpg',
          '/gallery/bory2/vystavba/2021_03BH2-SUNSET-lowres-1029.jpg',
          '/gallery/bory2/vystavba/2021_03BH2-SUNSET-lowres-1015.jpg',
          '/gallery/bory2/vystavba/2021_03BH2-SUNSET-lowres-1011.jpg',
          '/gallery/bory2/vystavba/2021_03BH2-SUNSET-lowres-1002.jpg',
        ],
        [
          '/gallery/bory2/vystavba/2021_02edit_BH2-JAN-2021-lowres-1022.jpg',
          '/gallery/bory2/vystavba/2021_02BH2-JAN-2021-lowres-1076.jpg',
          '/gallery/bory2/vystavba/2021_02BH2-JAN-2021-lowres-1030.jpg',
          '/gallery/bory2/vystavba/2021_02BH2-JAN-2021-lowres-1021.jpg',
          '/gallery/bory2/vystavba/2021_02BH2-JAN-2021-lowres-1011.jpg',
        ],
        [
          '/gallery/bory2/vystavba/2020_12CELE-BORY-SNEH-lowres-1038.jpg',
          '/gallery/bory2/vystavba/2020_12CELE-BORY-SNEH-lowres-1034.jpg',
          '/gallery/bory2/vystavba/2020_12CELE-BORY-SNEH-lowres-1031.jpg',
          '/gallery/bory2/vystavba/2020_12CELE-BORY-SNEH-lowres-1026.jpg',
          '/gallery/bory2/vystavba/2020_12CELE-BORY-SNEH-lowres-1021.jpg',
        ],
        [
          '/gallery/bory2/vystavba/2020_11BH2-OKT-EXT-lr-1034.jpg',
          '/gallery/bory2/vystavba/2020_11BH2-OKT-EXT-lr-1027.jpg',
          '/gallery/bory2/vystavba/2020_11BH2-OKT-EXT-lr-1002.jpg',
          '/gallery/bory2/vystavba/2020_11BH2-BYTY-lr-1027.jpg',
          '/gallery/bory2/vystavba/2020_11BH2-BYTY-lr-1020.jpg',
        ],
        [
          '/gallery/bory2/vystavba/2020_10BH2-OKT-FONTANA+JAZIERKO-fr-1012.jpg',
          '/gallery/bory2/vystavba/2020_10BH2-OKT-FONTANA+JAZIERKO-fr-1008.jpg',
          '/gallery/bory2/vystavba/2020_10BH2-OKT-EXT-lr-1011.jpg',
          '/gallery/bory2/vystavba/2020_10BH2-OKT-EXT-lr-1009.jpg',
          '/gallery/bory2/vystavba/2020_10BH2-OKT-EXT-lr-1004.jpg',
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-1.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-2.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-3.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-4.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-5.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-6.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-7.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-8.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-9.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-10.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-11.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-12.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-13.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-14.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-15.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-16.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-17.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-18.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-19.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-20.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-21.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-22.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-23.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-24.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-25.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-26.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-27.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-28.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-29.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-30.jpg'
        ],
        [
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-31.jpg',
          '/gallery/bory2/vystavba/Bory-Byvanie-2-vystavba-32.jpg'
        ]
      ],
      faza2: [
        [
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1001.jpg',
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1013.jpg',
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1016.jpg',
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1018.jpg',
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1040.jpg',
        ],
        [
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1042.jpg',
          '/gallery/bory2/faza2/BH2B-JAN-2023-lowres-1052.jpg',
          '/gallery/bory2/faza2/3_BH2B-OKT-EXTERIER-lowres-1039.jpg',
          '/gallery/bory2/faza2/4_BH2B-OKT-EXTERIER-lowres-1028.jpg',
          '/gallery/bory2/faza2/5_BH2B-OKT-EXTERIER-lowres-1068.jpg',
        ],
        [
          '/gallery/bory2/faza2/6_BH2B-OKT-EXTERIER-lowres-1034.jpg',
          '/gallery/bory2/faza2/7_BH2B-OKT-EXTERIER-lowres-1030.jpg',
          '/gallery/bory2/faza2/8_BH2B-OKT-EXTERIER-lowres-1069.jpg',
          '/gallery/bory2/faza2/1_BH2B+BH4-lowres-1007.jpg',
          '/gallery/bory2/faza2/2_BH2B+BH4-lowres-1010.jpg',
        ],
        [
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1052.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1051.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1050.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1045.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1042.jpg',
        ],
        [
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1041.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1039.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1038.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1028.jpg',
          '/gallery/bory2/faza2/BH2B-OKT-lowres-1027.jpg',
        ],
        [
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-10150.jpg',
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-1032.jpg',
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-1029.jpg',
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-1017.jpg',
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-1013.jpg',
        ],
        [
          '/gallery/bory2/faza2/2021_07BH2B-JUL-lowres-1003.jpg',
          '/gallery/bory2/faza2/2021_05BH2B-MAJ-2048px-1030.jpg',
          '/gallery/bory2/faza2/2021_05BH2B-MAJ-2048px-1028.jpg',
          '/gallery/bory2/faza2/2021_05BH2B-MAJ-2048px-1027.jpg',
          '/gallery/bory2/faza2/2021_05BH2B-MAJ-2048px-1023.jpg',
        ],
        [
          '/gallery/bory2/faza2/2021_05BH2B-MAJ-2048px-1013.jpg',
          '/gallery/bory2/faza2/2021_03BH2-DVA-MAR-2021-lowres-1009.jpg',
          '/gallery/bory2/faza2/2021_03BH2-DVA-MAR-2021-lowres-1006.jpg',
          '/gallery/bory2/faza2/2021_03BH2-DVA-MAR-2021-lowres-1004.jpg',
          '/gallery/bory2/faza2/2021_03BH2-DVA-MAR-2021-lowres-1003.jpg',
        ],
        [
          '/gallery/bory2/faza2/2021_03BH2-DVA-MAR-2021-lowres-1002.jpg',
        ]
      ],
      b3exterier: [
        [
          '/gallery/bory3/exterier/Bory-Byvanie-3-exterier-1.jpg',
          '/gallery/bory3/exterier/Bory_Promenada-1.jpg',
          '/gallery/bory3/exterier/Bory-Byvanie-3-exterier-3.jpg',
          '/gallery/bory3/exterier/Bory-Byvanie-3-exterier-4.jpg',
          '/gallery/bory3/exterier/Bory_Promenada-2.jpg'
        ],
        [
          '/gallery/bory3/exterier/Bory_Promenada-3.jpg',
          '/gallery/bory3/exterier/Bory-Byvanie-3-exterier-7.jpg'
        ]
      ],
      b3interier: [
        [
          '/gallery/bory3/interier/Bory-Byvanie-3-interier-1.jpg',
          '/gallery/bory3/interier/Bory-Byvanie-3-interier-2.jpg',
          '/gallery/bory3/interier/Bory-Byvanie-3-interier-3.jpg',
          '/gallery/bory3/interier/Bory-Byvanie-3-interier-4.jpg',
          '/gallery/bory3/interier/Bory-Byvanie-3-interier-5.jpg'
        ]
      ],
      b3vystavba: [
        [
          '/gallery/bory3/vystavba/9_BH3-IHRISKA-FEB-2023-lowres-1018.jpg',
          '/gallery/bory3/vystavba/8_BH3-IHRISKA-FEB-2023-lowres-1027.jpg',
          '/gallery/bory3/vystavba/7_Bory_BH3-32.jpg',
          '/gallery/bory3/vystavba/6_Bory_BH3-10.jpg',
          '/gallery/bory3/vystavba/5_Bory_BH3-8.jpg',
        ],
        [
          '/gallery/bory3/vystavba/4_Bory_BH3-35.jpg',
          '/gallery/bory3/vystavba/3_Bory_BH3-53.jpg',
          '/gallery/bory3/vystavba/2_Bory_BH3-57.jpg',
          '/gallery/bory3/vystavba/1_Bory_BH3-40.jpg',
          '/gallery/bory3/vystavba/BH3-NOV-lowres-1009.jpg',
        ],
        [
          '/gallery/bory3/vystavba/BH3-NOV-lowres-1021.jpg',
          '/gallery/bory3/vystavba/BH3-MAR-2022-lowres-1008.jpg',
          '/gallery/bory3/vystavba/BH3-MAR-2022-lowres-1025.jpg',
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1039.jpg',
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1037.jpg',
        ],
        [
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1020.jpg',
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1012.jpg',
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1006.jpg',
          '/gallery/bory3/vystavba/BH3-OKT-lowres-1003.jpg',
          '/gallery/bory3/vystavba/2021_07BH3-JUL-lowres-1052.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2021_07BH3-JUL-lowres-1050.jpg',
          '/gallery/bory3/vystavba/2021_07BH3-JUL-lowres-1048.jpg',
          '/gallery/bory3/vystavba/2021_07BH3-JUL-lowres-1014.jpg',
          '/gallery/bory3/vystavba/2021_07BH3-JUL-lowres-1012.jpg',
          '/gallery/bory3/vystavba/2021_05BH3-MAJ-2021-2048px-1043.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2021_05BH3-MAJ-2021-2048px-1037.jpg',
          '/gallery/bory3/vystavba/2021_05BH3-MAJ-2021-2048px-1010.jpg',
          '/gallery/bory3/vystavba/2021_05BH3-MAJ-2021-2048px-1006.jpg',
          '/gallery/bory3/vystavba/2021_05BH3-MAJ-2021-2048px-1004.jpg',
          '/gallery/bory3/vystavba/2021_03BH3-MAR-2021-lowres-1067.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2021_03BH3-MAR-2021-lowres-1051.jpg',
          '/gallery/bory3/vystavba/2021_03BH3-MAR-2021-lowres-1043.jpg',
          '/gallery/bory3/vystavba/2021_03BH3-MAR-2021-lowres-1019.jpg',
          '/gallery/bory3/vystavba/2021_03BH3-MAR-2021-lowres-1001.jpg',
          '/gallery/bory3/vystavba/2021_02BH3-FEB-2021--lowres-1063.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2021_02BH3-FEB-2021--lowres-1058.jpg',
          '/gallery/bory3/vystavba/2021_02BH3-FEB-2021--lowres-1051.jpg',
          '/gallery/bory3/vystavba/2021_02BH3-FEB-2021--lowres-1013.jpg',
          '/gallery/bory3/vystavba/2021_02BH3-FEB-2021--lowres-1005.jpg',
          '/gallery/bory3/vystavba/2020_12BH3-DEC-lowres-1041.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2020_12BH3-DEC-lowres-1027.jpg',
          '/gallery/bory3/vystavba/2020_12BH3-DEC-lowres-1014.jpg',
          '/gallery/bory3/vystavba/2020_12BH3-DEC-lowres-1011.jpg',
          '/gallery/bory3/vystavba/2020_11BH-NOV-lowres-1015.jpg',
          '/gallery/bory3/vystavba/2020_11BH-NOV-lowres-1014.jpg',
        ],
        [
          '/gallery/bory3/vystavba/2020_11BH-NOV-lowres-1012.jpg',
          '/gallery/bory3/vystavba/2020_11BH-NOV-lowres-1010.jpg',
          '/gallery/bory3/vystavba/2020_11BH-NOV-lowres-1009.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-1.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-10.jpg',
        ],
        [
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-2.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-3.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-4.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-5.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-6.jpg',
        ],
        [
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-7.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-8.jpg',
          '/gallery/bory3/vystavba/Bory-Byvanie-3-vystavba-9.jpg'
        ]
      ],
      b4vystavba: [
        [
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-106.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-117.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-123.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-125.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-155.jpg',
        ],
        [
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-171.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-184.jpg',
          '/gallery/bory4/vystavba/BH4-EXT-SEP-2023-lowres-195.jpg',
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-105.jpg',
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-122.jpg',
        ],
        [
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-124.jpg',
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-137.jpg',
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-144.jpg',
          '/gallery/bory4/vystavba/BH4-INT-SEP-2023-lowres-159.jpg',
          '/gallery/bory4/vystavba/Bory_BH4-4.jpg',
        ],
        [
          '/gallery/bory4/vystavba/Bory_BH4-21.jpg',
          '/gallery/bory4/vystavba/Bory_BH4-28.jpg',
          '/gallery/bory4/vystavba/Bory_BH4-35.jpg',
          '/gallery/bory4/vystavba/7_BH4-NOV-lowres-1016.jpg',
          '/gallery/bory4/vystavba/8_BH4-NOV-lowres-1007.jpg',
        ],
        [
          '/gallery/bory4/vystavba/9_BH4-NOV-lowres-1018.jpg',
          '/gallery/bory4/vystavba/10_BH4-NOV-lowres-1009.jpg',
          '/gallery/bory4/vystavba/11_BH4-NOV-lowres-1038.jpg',
          '/gallery/bory4/vystavba/12_BH4-NOV-lowres-1024.jpg',
          '/gallery/bory4/vystavba/13_BH4-NOV-lowres-1023.jpg',
        ],
        [
          '/gallery/bory4/vystavba/14_BH4-NOV-lowres-1041.jpg',
          '/gallery/bory4/vystavba/15_BH4-NOV-lowres-1004.jpg',
          '/gallery/bory4/vystavba/vystavba14.jpg',
          '/gallery/bory4/vystavba/vystavba15.jpg',
          '/gallery/bory4/vystavba/vystavba16.jpg',
        ],
        [
          '/gallery/bory4/vystavba/vystavba17.jpg',
          '/gallery/bory4/vystavba/vystavba18.jpg',
          '/gallery/bory4/vystavba/vystavba19.jpg',
          '/gallery/bory4/vystavba/vystavba20.jpg',
          '/gallery/bory4/vystavba/vystavba21.jpg',
        ],
        [
          '/gallery/bory4/vystavba/vystavba22.jpg',
          '/gallery/bory4/vystavba/vystavba01.jpg',
          '/gallery/bory4/vystavba/vystavba02.jpg',
          '/gallery/bory4/vystavba/vystavba03.jpg',
          '/gallery/bory4/vystavba/vystavba04.jpg',
        ],
        [
          '/gallery/bory4/vystavba/vystavba05.jpg',
          '/gallery/bory4/vystavba/vystavba06.jpg',
          '/gallery/bory4/vystavba/vystavba07.jpg',
          '/gallery/bory4/vystavba/vystavba08.jpg',
          '/gallery/bory4/vystavba/vystavba09.jpg',
        ],
        [
          '/gallery/bory4/vystavba/vystavba10.jpg',
          '/gallery/bory4/vystavba/vystavba11.jpg',
          '/gallery/bory4/vystavba/vystavba12.jpg',
          '/gallery/bory4/vystavba/vystavba13.jpg',
        ]
      ],
      b4interier: [
        [
          '/gallery/bory4/interier/interier01.jpg',
          '/gallery/bory4/interier/interier02.jpg',
          '/gallery/bory4/interier/interier03.jpg',
          '/gallery/bory4/interier/interier04.jpg',
          '/gallery/bory4/interier/interier05.jpg'
        ],
        [
          '/gallery/bory4/interier/interier06.jpg'
        ]
      ],
      b4exterier: [
        [
          '/gallery/bory4/exterier/nahradzi01.jpg',
          '/gallery/bory4/exterier/nahradzi02.jpg',
          '/gallery/bory4/exterier/nahradzi03.jpg',
          '/gallery/bory4/exterier/nahradzi04.jpg',
          '/gallery/bory4/exterier/nahradzi05.jpg'
        ]
      ],
      b5interier: [
        [
          '/gallery/bory5/interier/01C.jpg',
          '/gallery/bory5/interier/02A.jpg',
          '/gallery/bory5/interier/03A.jpg',
          '/gallery/bory5/interier/04A.jpg'
        ]
      ],
      b5exterier: [
        [
          '/gallery/bory5/exterier/nadvorie01.jpg',
          '/gallery/bory5/exterier/nadvorie02.jpg',
          '/gallery/bory5/exterier/nadvorie03.jpg',
          '/gallery/bory5/exterier/nadvorie04.jpg',
          '/gallery/bory5/exterier/nadvorie05.jpg'
        ],
        [
          '/gallery/bory5/exterier/nadvorie06.jpg',
        ]
      ],
      images: null
    };
  },
  methods: {
    showGallery(gallery) {
      this.activeLink = ''
      this.images = gallery
      this.$refs.slick2.destroy()
      this.$nextTick(() => {
        this.$refs.slick2.create()
      })
    },
    selectActive(el) {
      this.activeLink = el;
      console.log(el)
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.images = this.b1vystavba
  }
}
</script>

<style lang="sass" scoped>
$md: 768px
.gallery
  .navs-gallery
    padding: 30px 30px 10px
    background: #2D323A
    flex-wrap: wrap
    justify-content: center

    @media (max-width: $md)
      padding-top: 70px
      max-width: 100%
      flex-wrap: nowrap
      overflow-x: auto
      -webkit-overflow-scrolling: touch
      -ms-overflow-style: -ms-autohiding-scrollbar
      padding-bottom: 0
      justify-content: flex-start
      padding-left: 15px
      padding-right: 15px

      &::-webkit-scrollbar
        display: none
    .text-link
      color: #fff
      font-size: 18px
      line-height: 22px
      font-weight: 500
    .gallery-tab
      width: 20%
      @media (max-width: $md)
        width: auto
        flex: 0 0 auto
        border: 1px solid rgba(217, 217, 217, 0.2)
        margin-right: 10px
        width: 111px
      a
        width: 100%
        text-align: center
        padding: 33px 0

        @media (max-width: $md)
          padding: 15px 0

        &:hover
          background: rgba(72, 82, 94, 0.3)

        &.active
          background: #32434E
      img
        height: 95px
        @media (max-width: $md)
          height: 68px
  .sub-navs-gallery
    padding: 30px
    @media (max-width: $md)
      padding: 30px 15px
    h3
      font-weight: 400
      font-size: 40px
      line-height: 45px
      margin-bottom: 30px

      @media (max-width: $md)
        font-size: 32px
    .stage-nav
      display: flex
      width: 100%

      @media (max-width: $md)
        max-width: 100%
        flex-wrap: nowrap
        overflow-x: auto
        -webkit-overflow-scrolling: touch
        -ms-overflow-style: -ms-autohiding-scrollbar
        padding-bottom: 0
        justify-content: flex-start

        &::-webkit-scrollbar
          display: none

      a
        padding: 13px 39px 13px 16px
        border-bottom: 1px solid #fff
        color: #fff
        font-weight: 600
        font-size: 16px
        line-height: 24px
        text-decoration: none
        width: inherit

        @media (max-width: $md)
          width: auto
          flex: 0 0 auto

        &.active
          background: rgba(119, 185, 217, 0.2)
          border-bottom: 2px solid #77B9D9
          color: #77B9D9

          &:hover
            border-bottom: 2px solid #77B9D9

        &:hover
          background: rgba(119, 185, 217, 0.2)
          border-bottom: 1px solid #77B9D9
          color: #77B9D9
  .gallery-content
    background: var(--darkBlue)
.images
  padding: 20px
  //display: grid
  //grid-template-columns: repeat(6, 1fr)
  //grid-gap: 20px
  display: grid!important
  grid-template-columns: repeat(6, 1fr)
  grid-column-gap: 20px
  grid-row-gap: 20px
  @media (max-width: $md)
    display: flex!important
    flex-wrap: wrap
    grid-template-columns: unset
    grid-row-gap: unset
    grid-column-gap: unset
  .photo
    position: relative
    cursor: pointer

    &:before
      content: ""
      background: transparent
      transition: all .2s ease

    &:hover
      &:before
        content: ""
        width: 100%
        height: 100%
        background: rgba(0, 0, 0, 0.6)
        position: absolute
        top: 0
        left: 0
      &:after
        content: ""
        width: 36px
        height: 36px
        background: url(../assets/images/plus-icon.svg) no-repeat
        background-size: contain
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        pointer-events: none
    @media (max-width: $md)
      margin-bottom: 16px
    
    &:nth-child(5n+1)
      grid-area: 1 / 1 / 3 / 3
      height: 490px
      @media (max-width: $md)
        width: 100%
        height: 25vh
        grid-area: none
    &:nth-child(5n+2)
      grid-area: 1 / 3 / 4 / 7
      height: 525px
      @media (max-width: $md)
        width: 48%
        margin-right: 2%
        grid-area: none
        height: auto
        padding-bottom: 50%
    &:nth-child(5n+3)
      grid-area: 3 / 1 / 6 / 3
      height: 325px
      @media (max-width: $md)
        width: 48%
        margin-left: 2%
        grid-area: none
        height: auto
        padding-bottom: 50%
    &:nth-child(5n+4)
      grid-area: 4 / 3 / 6 / 5
      height: 290px
      @media (max-width: $md)
        width: 48%
        height: auto
        grid-area: none
        margin-right: 2%
        padding-bottom: 50%
    &:nth-child(5n+5)
      grid-area: 4 / 5 / 6 / 7
      height: 290px
      @media (max-width: $md)
        width: 48%
        height: auto
        grid-area: none
        margin-left: 2%
        padding-bottom: 50%
    img
      opacity: 0
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0


.dark-bg
  background: #2D323A
  padding-top: 85px
  @media (max-width: $md)
    padding-top: 0
.gallery-slider
  background: #2D323A
  height: 75vh
  .gallery-slide
    height: 75vh
    &.slide-1
      background: url(../assets/images/gallery-slides/1.jpg) center/cover no-repeat
    &.slide-2
      background: url(../assets/images/gallery-slides/2.jpg) center/cover no-repeat
    &.slide-3
      background: url(../assets/images/gallery-slides/3.jpg) center/cover no-repeat
    &.slide-4
      background: url(../assets/images/gallery-slides/4.jpg) center/cover no-repeat
    &.slide-5
      background: url(../assets/images/gallery-slides/5.jpg) center/cover no-repeat
    &.slide-6
      background: url(../assets/images/gallery-slides/6.jpg) center/cover no-repeat

</style>