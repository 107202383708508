<template>
  <div>
    <div class="home d-flex">
      <div class="intro-text d-flex align-items-end px-md-5">
        <div>
          <h1>{{ $t('Bory Na Hrádzi') }}</h1>
          <p v-html="$t('Nová zelená a tichšia etapa sa nachádza')"></p>
          <b-button href="../nasa-stvrt" class="d-block d-md-inline-block" squared variant="primary">{{ $t('O bývaní na Boroch') }}</b-button>
          <b-button href="https://konfiguratorbytu.sk/bory-na-hradzi/public/" class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0 btn btn-white">{{$t('Virtuálna prehliadka bytov')}}</b-button>
          <b-button href="https://konfiguratorbytu.sk/bory-na-hradzi/public/#exterier" target="_blank" class="d-block d-md-inline-block ml-md-3 mt-2 mt-md-0 btn btn-white">Virtuálna prehliadka exteriérov</b-button>
        </div>
      </div>
    </div>

    <section class="district p-md-3 pt-3 pt-md-5">
      <div class="d-flex flex-wrap align-items-start top mb-5">
        <div class="col-md-6 px-md-5">
          <h2 class="mb-4 m-md-0">{{ $t('Mesto tak, ako ho poznáte') }}</h2>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <p class="m-0">{{ $t('Bory kombinujú aktívne rodinné bývanie') }}</p>
        </div>
      </div>
      <div id="mapbox">
        <B4Map/>
      </div>
    </section>

    <section id="tabulka">
      <FlatsTable :stages="['bory4']" :showStageAndDate="false" :show-vr="true"></FlatsTable>
    </section>

    <section class="schedule p-3 p-md-5">
      <h2 class="mb-5">{{ $t('Harmonogram výstavby') }}</h2>
      <div class="d-flex flex-wrap">
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/zahajenie-vystavby.svg">
          </div>
          <h3 v-html="$t('Začiatok<br> výstavby')"></h3>
          <div class="line fill"></div>
          <p class="date">{{ $t('3. kvartál 2021') }}</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/hruba-stavba.svg">
          </div>
          <h3 v-html="$t('Žiadosť<br> o kolaudáciu')"></h3>
          <div class="line fill"></div>
          <p class="date">{{ $t('3. kvartál 2023') }}</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/kolaudacia.svg">
          </div>
          <h3 v-html="$t('Kolaudačný<br> proces')"></h3>
          <div class="line fill"></div>
          <p class="date">{{ $t('4. kvartál 2023') }}</p>
        </div>
        <div class="col-md p-0 phase fill">
          <div class="icon">
            <img src="../assets/icons/odovzdanie-bytov.svg">
          </div>
          <h3 v-html="$t('Odovzdávanie<br> bytov')"></h3>
          <div class="line fill-min"></div>
          <p class="date">{{ $t('1. - 2. kvartál 2024') }}</p>
        </div>
        <div class="col-md p-0 phase">
          <div class="icon">
            <img src="../assets/icons/kluce-byvanie.svg">
          </div>
          <h3 v-html="$t('Vaše<br> bývanie')"></h3>
          <div class="line transparent"></div>
        </div>
      </div>
    </section>

    <section class="section-3 p-3">
      <div class="">
        <div class="row px-3 mb-3">
          <div class="col-md-7 bg-img"></div>
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2 v-html="$t('Buďte pri tom,<br> na čom záleží')"></h2>
            <p class="mb-5" v-html="$t('Nezmeškajte žiadny z pamätných momentov svojich')"></p>
            <b-button href="https://konfiguratorbytu.sk/bory-na-hradzi/public/#exterier" class="d-block d-md-inline-block mb-4" target="_blank" squared variant="primary">{{ $t('Virtuálna prehliadka exteriérov') }}</b-button>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-1">
              <h3>{{ $t('Cyklochodník pri dome') }}</h3>
              <p>{{$t('Na zdravý životný štýl činky netreba.')}}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-2">
              <h3>{{ $t('Zóna bez áut') }}</h3>
              <p>{{ $t('Aj vo väčšom meste sa dá prežiť krásne detstvo') }}</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-12 p-md-5 bg-col bg-col-3">
              <h3>{{$t('Detské ihriská')}}</h3>
              <p>{{ $t('Štvrť plná detských ihrísk sa postará o nové') }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="row px-3 mb-3">
          <div class="col-md-5 p-0 px-md-5 py-3">
            <h2>Domov, ktorý očarí</h2>
            <p>Na pocite domova záleží. Zhmotnite svoje vízie o bývaní a vyberte si z rozšírenej ponuky štandardov. Kvalitné materiály a veľký výber dekorov až v troch úrovniach. Staňte sa súčasťou štvte navrhnutej pre život a vytvorte si bývanie podľa vašich predstáv.</p>
          </div>
          <div class="col-md-7 bg-img-2"></div>
        </div> -->
      </div>
    </section>

    <section class="about-bottom p-md-3 pl-md-5">
      <div class="d-flex flex-wrap">
        <div class="col-md-4 py-4">
          <h2 class="mb-4">{{$t('Domov, ktorý očarí')}}</h2>
          <p class="mb-3">{{ $t('Na pocite domova záleží.') }}</p>
        </div>
        <div class="col-md-8 pb-3 pb-md-0 pr-md-0">
          <div class="right-img bg-img-2"></div>
          <!-- <div id="standards-slider">
            <div class="na-hradzi">
              <slick
                  ref="slickStandard"
                  :options="slickOptions"
              >
                <div class="slide slide-1">
                  <img src="/standards/na-hradzi/slide-1.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-1" @click="toggleBox('info-1')">
                      <div class="box box-1 p-3" data-box="info-1">
                        <h4>VYKUROVANIE A PRÍPRAVA TEPLEJ ÚŽITKOVEJ VODY</h4>
                        <p>Na ohrev teplej úžitkovej vody a vykurovanie slúžia v bytových domoch centrálne kotolne, ktoré sú umiestnené v podzemnom podlaží obytných blokov. Vykurovanie izieb bytov je zabezpečené doskovými vykurovacími telesami s termostatickými hlavicami, vykurovanie kúpeľní je zabezpečené rebríkovým radiátorom.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2" @click="toggleBox('info-2')">
                      <div class="box box-2 p-3" data-box="info-2">
                        <h4>PODLAHY</h4>
                        <p>V predsieni, chodbe, obývacej izbe, izbách, kuchyni, vo vnútornom sklade alt. v šatníku alebo komore laminátová podlaha.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3" @click="toggleBox('info-3')">
                      <div class="box box-3 p-3" data-box="info-3">
                        <h4>DELIACE MEDZIBYTOVÉ STENY</h4>
                        <p class="mb-3">Železobetónové steny hrúbky 220 alebo 250 mm.</p>
                        <h4>DELIACE INTERIÉROVÉ PRIEČKY</h4>
                        <p>Z keramických tvárnic hrúbky 80, 115 alebo 140 mm.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-2">
                  <img src="/standards/na-hradzi/slide-2.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-2-1" @click="toggleBox('info-2-1')">
                      <div class="box box-1 p-3" data-box="info-2-1">
                        <h4>OKNÁ</h4>
                        <p>Plastové okná s izolačným trojsklom. Farba okenných rámov a parapetov z vnútornej strany biela, z vonkajšej strany čiernosivá.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2-2" @click="toggleBox('info-2-2')">
                      <div class="box box-2 p-3" data-box="info-2-2">
                        <h4>EXTERIÉR</h4>
                        <p>Na balkónoch, loggiách a terasách exteriérové gresové dlažby do exteriéru, uložené na rektifikačných terčoch. Na terasách predzáhradok podlahy založené v štrkových lôžkach. Oceľové zábradlia. Na predzáhradkách plot.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-2-3" @click="toggleBox('info-2-3')">
                      <div class="box box-3 p-3" data-box="info-2-3">
                        <h4>TIENENIE</h4>
                        <p>Kastlík s vonkajšími elektrickými žalúziami.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-3">
                  <img src="/standards/na-hradzi/slide-3.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-3-1" @click="toggleBox('info-3-1')">
                      <div class="box box-1 p-3" data-box="info-3-1">
                        <h4>VSTUPNÉ DVERE DO BYTU</h4>
                        <p>Vstupné bezpečnostné dvere v bezpečnostnej triede 3 s kovaním guľa-kľučka a s priezorníkom; farba z vonkajšej strany RAL 1019, z vnútornej strany RAL 9003.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3-2" @click="toggleBox('info-3-2')">
                      <div class="box box-2 p-3" data-box="info-3-2">
                        <h4>INTERIÉROVÉ DVERE</h4>
                        <p>Bezfalcové/polodrážkové dvere, plné dverné krídlo z DTD, povrchová úprava CPL/ dýha/ lak, obložková zárubňa, skryté/priznané závesy, magnetický zámok/ klasické kovanie – v prevedení podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-3-3" @click="toggleBox('info-3-3')">
                      <div class="box box-3 p-3" data-box="info-3-3">
                        <h4>VZDUCHOTECHNIKA</h4>
                        <p>Pre potreby hygienického vetrania bytov, pri dodržaní hygienických a akustických noriem, sú v obvodových stenových konštrukciách inštalované stenové vetracie mriežky. Podtlak zabezpečujú ventilátory vo WC a v kúpeľni. Predpríprava vzduchotechnického napojenia kuchynského digestora je navrhnutá formou odbočky zo stúpacieho vzduchotechnického potrubia.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-4">
                  <img src="/standards/na-hradzi/slide-4.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-4-1" @click="toggleBox('info-4-1')">
                      <div class="box box-3 p-3" data-box="info-4-1">
                        <h4>VAŇA</h4>
                        <p>Smaltovaná oceľová vaňa vrátane vaňovej batérie a ručnej sprchy v prevedení podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-4-2" @click="toggleBox('info-4-2')">
                      <div class="box box-1 p-3" data-box="info-4-2">
                        <h4>PODLAHA A OBKLAD</h4>
                        <p>Gresová dlažba a obklad. <br>Farebné prevedenie a veľkosť formátu podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-4-3" @click="toggleBox('info-4-3')">
                      <div class="box box-2 p-3" data-box="info-4-3">
                        <h4>UMÝVADLO</h4>
                        <p>Umývadlo bielej farby a umývadlová batéria v prevedení podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide slide-5">
                  <img src="/standards/na-hradzi/slide-5.jpg">
                  <div class="standards-info">
                    <div class="button-toggle info-5-1" @click="toggleBox('info-5-1')">
                      <div class="box box-1 p-3" data-box="info-5-1">
                        <h4>SPRCHA</h4>
                        <p>Sprchová zástena s gresovou dlažbou a sprchovým žľabom vrátane sprchovej batérie a sprchového kompletu v prevedení podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                    <div class="button-toggle info-5-2" @click="toggleBox('info-5-2')">
                      <div class="box box-2 p-3" data-box="info-5-2">
                        <h4>TOALETA</h4>
                        <p class="mb-3">Závesná WC misa bielej farby a tlačidlo v prevedení podľa výberu klienta z ponuky štandardov.</p>
                        <h4>UMÝVADLO</h4>
                        <p>V samostatnom WC malé umývadielko bielej farby a umývadielková batéria v prevedení podľa výberu klienta z ponuky štandardov.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </slick>
            </div>
          </div> -->
        </div>
      </div>
    </section>

<!--    <section class="about-bottom p-md-3 pl-md-5">-->
<!--      <div class="d-flex flex-wrap">-->
<!--        <div class="col-md-6 py-4">-->
<!--          <h2 class="mb-4">Domov, ktorý zažiari</h2>-->
<!--          <p class="mb-3">Vďaka širokej ponuke štandardu si každý obyvateľ vyberie podľa svojich predstáv. <br>V snahe zabezpečiť vášmu bývaniu dlhú životnosť sme pre vás vybrali kvalitné materiály dostupné v rôznych farebných prevedeniach.</p>-->
<!--&lt;!&ndash;          <b-button squared variant="primary">Stiahnuť katalóg štandardov</b-button>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="col-md-6 pb-3 pb-md-0 pr-md-0">-->
<!--          <div class="right-img"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->

    <section class="about-bottom-2">
      <div class="d-flex flex-wrap">
        <div class="col-md-6 bg"></div>
        <div class="col-md-6 signature-section">
          <p class="mb-4 font-weight-bolder">{{ $t('Bory Na Hrádzi spája plne vybavené') }}</p>
        </div>
      </div>
    </section>

    <ContactForm selectedStage="bory4"></ContactForm>
  </div>
</template>

<script>
import FlatsTable from "@/components/FlatsTable";
import B4Map from "@/components/maps/B4Map";
import ScrollTo from "@/plugins/scrollTo.util";
import ContactForm from "@/components/ContactForm";

export default {
  name: "Bory4",
  components: {ContactForm, B4Map, FlatsTable},
  data() {
    return {
      slickOptions: {
        infinite: false,
        adaptiveHeight: true,
        dots: false,
        arrows: true
        // Any other options that can be got from plugin documentation
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 2
        //     }
        //   }
        //   // You can unslick at a given breakpoint now by adding:
        //   // settings: "unslick"
        //   // instead of a settings object
        // ]
      },
    }
  },
  mounted () {
    ScrollTo.hash(this,  () => window.scrollTo(0, 0))
  },
  methods: {
    toggleBox(val) {
      console.log(val)
      document.querySelector('.' + val).classList.toggle('clicked')
      document.querySelector('[data-box="' + val + '"]').classList.toggle('show')
    }
  }
}
</script>

<style lang="sass" scoped>
$md: 768px

#standards-slider
  max-width: 100%
  overflow: hidden
  .slide
    position: relative
  img
    height: 100%
  .na-hradzi
    .slide-1
      .standards-info
        .button-toggle
          &.info-1
            left: 8%
            top: 80%
          &.info-2
            top: auto
            bottom: 3%
            left: 56%
          &.info-3
            top: 30%
            left: 90%
    .slide-2
      .standards-info
        .button-toggle
          &.info-2-1
            left: 23%
            top: 25%
          &.info-2-2
            top: auto
            bottom: 25%
            left: 22%
          &.info-2-3
            top: 12%
            left: 38%
    .slide-3
      .standards-info
        .button-toggle
          &.info-3-1
            left: 62%
            top: 40%
          &.info-3-2
            top: 58%
            left: 62%
          &.info-3-3
            top: 50%
            left: 93%
    .slide-4
      .standards-info
        .button-toggle
          &.info-4-1
            left: 21%
            top: auto
            bottom: 15%
          &.info-4-2
            top: auto
            bottom: 10%
            left: 48%
          &.info-4-3
            top: auto
            bottom: 18%
            left: 80%
    .slide-5
      .standards-info
        .button-toggle
          &.info-5-1
            left: 43%
            top: 50%
          &.info-5-2
            top: auto
            bottom: 20%
            left: 52%

.na-hradzi
  .slide-1
    .standards-info
      .box
        &.box-1
          top: -120px
          left: 50px
        &.box-2
          top: auto
          bottom: 50px
          left: -160px
        &.box-3
          top: 0
          left: auto
          right: 50px
  .slide-2
    .standards-info
      .box
        &.box-1
          top: 50px
          right: -160px
        &.box-2
          top: 0
          left: 50px
        &.box-3
          top: 0
          left: 50px
  .slide-3
    .standards-info
      .box
        &.box-1
          bottom: 0
          left: auto
          right: 50px
        &.box-2
          top: 0
          left: auto
          right: 50px
        &.box-3
          bottom: 0
          left: auto
          right: 50px
  .slide-4
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 55px
          left: -160px
        &.box-2
          top: auto
          bottom: 55px
          left: -160px
        &.box-3
          top: auto
          bottom: 55px
          left: -160px
  .slide-5
    .standards-info
      .box
        &.box-1
          top: auto
          bottom: 0
          left: 55px
        &.box-2
          top: -90px
          left: auto
          right: 55px
.standards-info
  .box
    display: none
    position: absolute
    background: rgba(255, 255, 255, .8)
    width: 370px
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)

    &.show
      display: block

    h4, p
      color: var(--darkBlue)

    h4
      font-size: 16px
      font-weight: bold

    p
      margin-bottom: 0
      font-size: 14px
  .button-toggle
    position: absolute
    width: 44px
    height: 44px
    background: var(--blue)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) rotate(360deg)
    border-radius: 50%
    cursor: pointer
    z-index: 100
    box-shadow: 0px 15px 40px rgba(112, 144, 176, 0.4)
    transition: 0.8s cubic-bezier(0.2, 0.6, 0.3, 1.1)

  .button-toggle:after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 2px
    width: 50%
    background: white

  .button-toggle:before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 50%
    width: 2px
    background: white

  .button-toggle.clicked
    //transform: translate(-50%, -50%) rotate(180deg)
    background: var(--textDark)

  .button-toggle.clicked:before
    width: 0

.about-bottom-2
  .bg
    background: url('/gallery/bory4/exterier/nahradzi04.jpg') center/cover no-repeat
.about-bottom
  padding-bottom: 11px!important
  .right-img
    background: url(../assets/images/bory3/bory3-domov.jpg) center/cover no-repeat
  .bg-img-2
    background: url("../assets/images/banner-nahradzi.jpg") center/cover no-repeat
    @media (max-width: $md)
      height: 40vh
      background-size: cover

.schedule
  background: var(--textBright)
  .phase
    @media (max-width: $md)
      border-left: 2px solid var(--textDark)
      padding-left: 15px !important
      display: flex
      flex-direction: column
      padding-bottom: 20px !important
      &.fill
        border-color: var(--blue)
        &:before
          background: var(--blue)
      &.fill-half
        &:before
          background: var(--blue)
        &:after
          content: ""
          width: 2px
          height: 60%
          background: var(--blue)
          position: absolute
          left: -2px
          top: 0
      &:last-child
        border-color: transparent
      &:before
        content: ""
        width: 12px
        height: 12px
        background: var(--textDark)
        border-radius: 50%
        position: absolute
        left: -7px
        top: 0
        margin-top: 0
  h3
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.5px
    @media (max-width: $md)
      order: 3
  p.date
    font-size: 16px
    line-height: 20px
    letter-spacing: -0.5px
    color: var(--blue)
    font-weight: 500
    @media (max-width: $md)
      order: 1
      margin-top: -3px
  .icon
    height: 40px
    margin-bottom: 10px
    @media (max-width: $md)
      order: 2
  .line
    margin: 20px 0
    height: 2px
    background: var(--textDark)
    position: relative
    @media (max-width: $md)
      display: none
    &:before
      content: ""
      width: 12px
      height: 12px
      background: var(--textDark)
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      margin-top: -5px
    &.fill
      background: var(--blue)
      &:before
        background: var(--blue)
    &.fill-half, &.fill-min
      &:before
        background: var(--blue)
    &.fill-min
      &:after
        width: 15%
    &.transparent
      background: transparent
    &.fill-half
      &:after
        content: ""
        width: 50%
        height: 2px
        background: var(--blue)
        position: absolute
        left: 0

.section-3
  background: var(--grey)
  color: #fff
  text-align: left
  .bg-img
    background: url("/gallery/bory4/exterier/nahradzi02.jpg") center/cover no-repeat
  
  h2
    font-size: 55px
    line-height: 60px
    font-weight: 300
    margin-bottom: 20px
    @media (max-width: $md)
      font-size: 28px
      line-height: 40px
      letter-spacing: -1px
      margin-bottom: 15px
  p
    max-width: 320px
    font-weight: 500
    &:last-child
      margin-bottom: 220px
      @media (max-width: $md)
        margin-bottom: 0
  .bg-col
    height: 800px
    @media (max-width: $md)
      height: 60vh
      margin-bottom: 16px
      padding: 30px
    h3
      font-size: 28px
      line-height: 32px
      font-weight: 400
      margin-bottom: 20px
    p
      font-size: 16px
      line-height: 21px
      font-weight: 500
      @media (max-width: $md)
        font-size: 12px
        line-height: 16px
        max-width: 100%
  .bg-col-1
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("/gallery/bory4/exterier/nahradzi01.jpg") center/cover no-repeat
    background-position: left -530px center

    @media (max-width: $md)
      background-position: center
  .bg-col-2
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("/gallery/bory4/exterier/nahradzi05.jpg") bottom/cover no-repeat
    background-position: -160px center
  .bg-col-3
    background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("/gallery/bory4/exterier/nahradzi02.jpg") center/cover no-repeat
    background-position: -510px center
    @media (max-width: $md)
      background-position: center
  .col-md-4
    padding-left: 8px
    padding-right: 8px
.home
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/BH4-MAJ-EXT-lowres-1043.jpg") top/cover no-repeat
  background: linear-gradient(179.37deg, rgba(0, 0, 0, 0.53) 0.71%, rgba(0, 0, 0, 0) 99.63%), url("../assets/images/BH4-MAJ-EXT-lowres-1043.jpg") top/cover no-repeat
  p
    margin-bottom: 30px
    max-width: 770px

  .btn-white
    background: #fff
    color: #001F33
    border-radius: 0
    border-color: #fff
    position: relative
    padding-left: 55px

    &:before
      content: ""
      width: 27px
      height: 16px
      background: url("../assets/icons/360.svg") center/contain no-repeat
      position: absolute
      left: 15px
      top: 50%
      transform: translateY(-50%)

    &:hover
      background: #CFEDFF
      border-color: #CFEDFF

.district
  background: var(--darkBlue)
  img
    width: 100%
.top
  h2
    font-weight: 300
    font-size: 55px
    line-height: 60px
    letter-spacing: -3px
    @media (max-width: $md)
      font-size: 40px
      line-height: 40px
      letter-spacing: -2px
  p
    font-weight: 500
    max-width: 415px
</style>
